import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
  Divider,
} from "@mui/material";
import Authentication from "./Authentication";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { useLanguage } from "../LanguageContext";

interface CartItem {
  title: string;
  price: number;
  description: string;
  key: number;
  event: number;
}
interface History {
  reservedItems: CartItem[];
  reservedDate: string;
  reservedTime: string;
  // reservedMemo: CartItem;
}

interface ReservationHistoryProps {
  isVerified: boolean;
  setIsVerified: (query: boolean) => void;
  userName: string;
  setUserName: (query: string) => void;
  phoneNumber: string;
  setPhoneNumber: (query: string) => void;
}

const ReservationHistory: React.FC<ReservationHistoryProps> = ({
  isVerified,
  setIsVerified,
  userName,
  setUserName,
  phoneNumber,
  setPhoneNumber,
}) => {
  const [history, setHistory] = useState<History[]>();

  const { language } = useLanguage(); // 현재 언어 상태 가져오기

  //로컬 스토리지에서 불러온 데이터
  const bookingT = localStorage.getItem("reservedTime");
  const userD = localStorage.getItem("userData");

  //리렌더링 방지를 위한 인증 boolean
  const [historyVerified, setHistoryVerified] = useState<boolean>(() => {
    const storedValue = sessionStorage.getItem("verified");
    return storedValue === "true" ? true : false;
  });
  const [historyName, setHistoryName] = useState<string>(() => {
    const storedValue = sessionStorage.getItem("userName");
    return storedValue ? storedValue : "";
  });

  // 서버에서 불러온 데이터
  const fetchReservation = async (phoneNum: string): Promise<any> => {
    const today = dayjs().format("YYYY-MM-DD");
    const url = `https://reservation.api.receipt.smartdoctor.systems/reservation-api/v2/reservations?cellPhone=${encodeURIComponent(
      phoneNum
    )}&reservationDateWithinStart=${encodeURIComponent(
      today
    )}&reservationDateWithinEnd=2029-12-31`;
    const token =
      "9nnqrjQSAsGKT45khU7PsKUSVPxcTlgnDiLNvff6NMzLpsUf36WtnsAmpmsAeBGD1sgU43yhtHtZimniR2SSW3CiglKrlgbmX2i3IJCbWjTOlafJFQj35JmQkHRhwLK47YHlZlz6Y7BCQKLuAl677KL8fLnL0pNnI09q3";
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      const reservedHistory: History[] = data.reservations?.map(
        <
          T extends {
            customerName: string;
            etcMemo: string;
            reservationDate: string;
            reservationStartTime: string;
            reservationMemo: string;
          }
        >(
          item: T,
          index: number
        ) => {
          setUserName(item.customerName);
          const etcMemo = JSON.parse(item.etcMemo) as CartItem[];
          // console.log(item.etcMemo);
          return {
            reservedItems: etcMemo,
            reservedDate: item.reservationDate,
            reservedTime: item.reservationStartTime,
          };
        }
      );
      setHistory(reservedHistory);
    } catch (error) {
      console.error("Error fetching reservation data:", error);
      throw error; // 에러 발생 시 다시 throw해서 함수 호출자에게 전달
    }
  };

  function formatDate(dateString: string): string {
    // 날짜 문자열을 Date 객체로 변환
    const date = new Date(dateString);

    // 연도, 월, 일 추출
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // getMonth()는 0부터 시작하므로 1을 더함
    const day = date.getDate();

    // 원하는 형식으로 반환
    return `${year}년 ${month}월 ${day}일`;
  }

  function formatTime(timeString: string): string {
    // 시간 문자열을 ':'로 분리
    const [hour, minute] = timeString.split(":");

    // 원하는 형식으로 반환
    return `${hour}시${minute}분`;
  }

  function daysUntil(dateString: string): number {
    // 입력된 날짜를 Date 객체로 변환
    const targetDate = new Date(dateString);

    // 오늘 날짜를 생성
    const today = new Date();

    // 두 날짜 간의 차이를 계산 (밀리초 단위)
    const diffInMilliseconds = targetDate.getTime() - today.getTime();

    // 밀리초를 일 단위로 변환
    const diffInDays = Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24));

    return diffInDays;
  }
  useEffect(() => {
    if (historyVerified) {
      const storedPhoneNum = sessionStorage.getItem("phoneNumber");
      fetchReservation(storedPhoneNum ? storedPhoneNum : phoneNumber);
      // console.log(history);
    }
  }, [historyVerified]);
  useEffect(() => {
    const storedName = sessionStorage.getItem("userName");
    setHistoryName(storedName ? storedName : "");
    const storedPhoneNum = sessionStorage.getItem("phoneNumber");
    setPhoneNumber(storedPhoneNum ? storedPhoneNum : "");
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box
      sx={{
        position: "relative",
        backgroundColor: "#f6f6f2",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      {!historyVerified ? (
        <Authentication
          isVerified={historyVerified}
          setIsVerified={setHistoryVerified}
          userName={userName}
          setUserName={setUserName}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
        />
      ) : (
        <Box
          sx={{
            position: "relative",
            width: "100%",
          }}
        >
          <Box
            sx={{
              position: "relative",
              width: "100%",
              padding: 2,
            }}
          >
            <Typography
              sx={{
                fontFamily: "Pretendard",
                fontWeight: "500",
                color: "#202124",
                fontSize: "1.1rem",
              }}
            >
              {historyName} 님의 예약 내역
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: 2,
              position: "relative",
              width: "100%",
            }}
          >
            {/* {cartItems.length > 0 ? ( */}
            {history !== undefined ? (
              <Box
                sx={{
                  width: "100%",
                  height: "fit-content",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 1,
                }}
              >
                {history.map((item: History, index: number) => (
                  <Box
                    sx={{
                      position: "relative",
                      width: "85%",
                      backgroundColor: "white",
                      borderRadius: 1,
                      border: "0.5px solid #838383",
                      p: 2,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        gap: 2,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Pretendard",
                            fontWeight: "500",
                            color: "#202124",
                            fontSize: "0.95rem",
                          }}
                        >
                          예약일시: {formatDate(item.reservedDate)}{" "}
                          {formatTime(item.reservedTime)}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: "#3f3936",
                            borderRadius: "20px",
                            width: "fit-content",
                            height: "fit-content",
                            px: 1.5,
                            py: 0.7,
                          }}
                        >
                          <Typography
                            sx={{
                              fontFamily: "Pretendard",
                              fontWeight: "500",
                              color: "white",
                              fontSize: "0.75rem",
                              textAlign: "center",
                            }}
                          >
                            D-{daysUntil(item.reservedDate)}
                          </Typography>
                        </Box>
                      </Box>
                      <List>
                        <Divider
                          variant="middle"
                          sx={{
                            position: "relative",
                            width: "100%",
                            p: 0,
                            margin: 0,
                          }}
                        />
                        {item.reservedItems.length > 0 &&
                          item.reservedItems.map((i, ind) =>
                            i.title !== "USERMEMO" ? (
                              <ListItem
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "flex-start",
                                  padding: 0.5,
                                }}
                              >
                                <ListItemText
                                  primary={
                                    <Typography
                                      sx={{
                                        fontFamily: "Pretendard",
                                        fontWeight: "500",
                                        color: "#3f3936",
                                      }}
                                    >
                                      {i.title ? i.title : ""}
                                    </Typography>
                                  }
                                  secondary={
                                    <Typography
                                      sx={{
                                        fontFamily: "Pretendard",
                                        fontWeight: "500",
                                        color: "#838383",
                                        fontSize: "0.75rem",
                                      }}
                                    >
                                      {i.description ? (
                                        i.description
                                          .split("@")
                                          .map((line, index) => (
                                            <span key={index}>
                                              {line}
                                              <br />
                                            </span>
                                          ))
                                      ) : (
                                        <br />
                                      )}
                                    </Typography>
                                  }
                                />
                                <Typography
                                  sx={{
                                    fontFamily: "Pretendard",
                                    fontWeight: "500",
                                    color: "#3f3936",
                                    fontSize: "0.875rem",
                                  }}
                                >
                                  {i.price.toLocaleString("en-US")}원
                                </Typography>
                                <Divider
                                  variant="middle"
                                  sx={{
                                    position: "relative",
                                    width: "100%",
                                    p: 0,
                                    marginLeft: 0,
                                    marginTop: 2,
                                    marginBottom: 1,
                                  }}
                                />
                              </ListItem>
                            ) : (
                              i.description.length > 0 && (
                                <Typography
                                  sx={{
                                    marginTop: 2,
                                    fontFamily: "Pretendard",
                                    fontWeight: "500",
                                    color: "#3f3936",
                                    fontSize: "0.875rem",
                                  }}
                                >
                                  요청하신 내용: {i.description}
                                </Typography>
                              )
                            )
                          )}
                      </List>
                    </Box>
                  </Box>
                ))}
                <Box
                  position="relative"
                  sx={{
                    // bottom: "0%",
                    left: "0%",
                    mt: 2,
                    width: "100%",
                    height: "200px",
                  }}
                >
                  <a
                    href="http://pf.kakao.com/_xhxmizn/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button
                      variant="contained"
                      sx={{
                        left: "50%",
                        // bottom: "10%",
                        transform: "translateX(-50%)",
                        width: "90%",
                        height: 46,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: 1,
                        backgroundColor: "#3f3936",
                        borderRadius: 1,
                        border: "1px solid transparent",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Pretendard-SemiBold, Helvetica",
                          fontWeight: "bold",
                          color: "#f6f6f2",
                          fontSize: "0.875rem",
                          textAlign: "center",
                          whiteSpace: "nowrap",
                        }}
                      >
                        문의하기
                      </Typography>
                    </Button>
                  </a>
                </Box>
              </Box>
            ) : (
              <Box
                sx={{
                  position: "relative",
                  width: "100%",
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "white",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Pretendard",
                    fontWeight: "400",
                    color: "#202124",
                    fontSize: "0.9rem",
                    alignText: "center",
                  }}
                >
                  예약내역이 없습니다.
                </Typography>
                <Link to={`/${language}/reservation`}>
                  <Button
                    variant="outlined"
                    fullWidth
                    sx={{
                      mt: 3,
                      py: 0.5,
                      borderColor: "#827a72",
                      color: "#3f3936",
                      bgcolor: "#e3e0d7",
                      fontFamily: "Pretendard",
                      fontWeight: "500",
                      fontSize: "0.9rem",
                    }}
                  >
                    예약하러가기
                  </Button>
                </Link>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ReservationHistory;
