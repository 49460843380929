import React, { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Box, Tabs, Tab, Typography } from "@mui/material";
import doctor from "./images/doctor.jpg";
import way2pria from "./images/way2pria.png";
import { ref, getDownloadURL, listAll } from "firebase/storage";
import { storage } from "../service/firebaseConfig"; // Adjust the import path as needed
import third_interior_img from "./images/third_interior_img.jpg";
import fourth_interior_img from "./images/fourth_interior_img.jpg";
import { CSSTransition } from "react-transition-group";
import diamond_icon from "./images/diamond_icon.png";
import Interior_entrance from "./images/Interior_entrance.jpg";
import Interior_logo from "./images/Interior_logo.jpg";
import Interior_logo1 from "./images/Interior_logo1.jpg";
import { useLanguage } from "../LanguageContext";

type ImageUrl = {
  name: string;
  url: string;
};

const Introduction: React.FC = () => {
  // 각 섹션의 위치를 참조하기 위한 ref 생성

  // State to hold image URLs
  const [imageUrls, setImageUrls] = useState<ImageUrl[]>([]);

  // 첫 이미지 부드럽게 나타나는 애니메이션 적용 위한 timer
  const [inProp, setInProp] = useState(false);

  //서브 상단탭 보여주기
  const [isVisible, setIsVisible] = useState(true);
  const lastScrollY = useRef<number>(0); // 마지막 스크롤 위치 저x장

  const [tabState, setTabState] = useState<number>(0);
  const [isNaverBrowser, setIsNaverBrowser] = useState(false);
  const { language } = useLanguage(); // 현재 언어 상태 가져오기

  const sectionRefs = {
    section1: useRef<HTMLElement>(null),
    section2: useRef<HTMLElement>(null),
    section3: useRef<HTMLElement>(null),
    section4: useRef<HTMLElement>(null),
  };

  const handleScroller = (section: keyof typeof sectionRefs) => {
    const sectionElement = sectionRefs[section].current;
    if (sectionElement) {
      const topOffset =
        sectionElement.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: topOffset,
        behavior: "smooth",
      });
    }
  };
  const fetchImages = async () => {
    try {
      const folderRef = ref(storage, "Interiors");
      const result = await listAll(folderRef);

      for (const itemRef of result.items) {
        const url = await getDownloadURL(itemRef);
        setImageUrls((prevImageUrls) => [
          ...prevImageUrls,
          { name: itemRef.name, url },
        ]);
      }
    } catch (error) {
      console.error("Error fetching images from Firebase Storage:", error);
    }
  };

  useEffect(() => {
    setInProp(true); // 컴포넌트가 마운트된 직후 애니메이션 시작
    fetchImages();

    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY < lastScrollY.current || window.scrollY < 30) {
        setIsVisible(true); // 스크롤을 위로 올리면 표시
      } else {
        setIsVisible(false); // 스크롤을 아래로 내리면 숨김
      }
      lastScrollY.current = currentScrollY;
    };

    const userAgent = navigator.userAgent || "";
    if (userAgent.includes("NAVER")) {
      setIsNaverBrowser(true);
    }

    // 스크롤 이벤트 리스너 추가
    window.addEventListener("scroll", handleScroll);
    // 컴포넌트 언마운트 시 리스너 제거
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: "-30% 0px -70% 0px", // 섹션의 중간 부분이 뷰포트에 들어올 때 트리거
      threshold: 0,
    };

    const observerCallback = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          switch (entry.target) {
            case sectionRefs.section1.current:
              setTabState(0);
              break;
            case sectionRefs.section2.current:
              setTabState(1);
              break;
            case sectionRefs.section3.current:
              setTabState(2);
              break;
            case sectionRefs.section4.current:
              setTabState(3);
              break;
            default:
              break;
          }
        }
      });
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    );

    // 각 섹션에 대해 observer를 설정
    Object.values(sectionRefs).forEach((ref) => {
      if (ref.current) observer.observe(ref.current);
    });

    return () => {
      observer.disconnect();
    };
  }, [sectionRefs]);

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
      }}
    >
      <Box
        position="fixed"
        sx={{
          top: isVisible ? 90 : 50,
          width: "100%",
          height: "40px",
          maxWidth: "750px",
          zIndex: 50,
          backgroundColor: "white",
          transition: "top 0.3s ease",
          borderBottom: "1px solid black", // 아래쪽 border만 설정
        }}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-start"
      >
        <Tabs
          value={tabState}
          onChange={() => setTabState(tabState)}
          centered
          textColor="inherit" // 텍스트 색상을 상속받도록 설정
          TabIndicatorProps={{
            style: {
              backgroundColor: "black", // 인디케이터 색상 설정
              height: "2px", // 인디케이터 두께 설정
              width: "15%", // 인디케이터 길이 설정
              transform: "translateX(30%)", // 인디케이터 위치 조정
              bottom: "4px",
            },
          }}
        >
          <Tab
            label="병원 소개"
            sx={{
              fontSize: "0.85rem", // 텍스트 크기 설정
              fontFamily: "Pretendard", // 폰트 패밀리 설정
              fontWeight: "500", // 폰트 두께 설정
              color: "grey",
            }}
            onClick={() => {
              handleScroller("section1");
              setTabState(0);
            }}
          />
          <Tab
            label="의료진 소개"
            sx={{
              fontSize: "0.85rem", // 텍스트 크기 설정
              fontFamily: "Pretendard", // 폰트 패밀리 설정
              fontWeight: "500", // 폰트 두께 설정
              color: "grey",
            }}
            onClick={() => {
              handleScroller("section2");
              setTabState(1);
            }}
          />
          <Tab
            label="오시는 길"
            sx={{
              fontSize: "0.85rem", // 텍스트 크기 설정
              fontFamily: "Pretendard", // 폰트 패밀리 설정
              fontWeight: "500", // 폰트 두께 설정
              color: "grey",
            }}
            onClick={() => {
              handleScroller("section3");
              setTabState(2);
            }}
          />
          <Tab
            label="진료시간"
            sx={{
              fontSize: "0.85rem", // 텍스트 크기 설정
              fontFamily: "Pretendard", // 폰트 패밀리 설정
              fontWeight: "500", // 폰트 두께 설정
              color: "grey",
            }}
            onClick={() => {
              handleScroller("section4");
              setTabState(3);
            }}
          />
        </Tabs>
      </Box>
      <div
        style={{
          position: "relative",
          top: 0,
          backgroundColor: "white",
        }}
      >
        {/* 각 섹션 */}
        <Box
          ref={sectionRefs.section1}
          id="section1"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "10px",
            backgroundColor: "#fff",
            padding: 0,
            paddingTop: 10,
          }}
        >
          <CSSTransition
            in={inProp}
            timeout={1200}
            unmountOnExit
            onEnter={(node: HTMLElement) => {
              node.style.opacity = "0"; // 초기 상태 (투명)
            }}
            onEntering={(node: HTMLElement) => {
              node.style.transition = "opacity 1200ms ease-in-out"; // 트랜지션 적용
              node.style.opacity = "1"; // 서서히 나타남
            }}
          >
            <img
              src={Interior_logo}
              alt="Image"
              style={{ width: "80%", height: "auto", objectFit: "cover" }}
            />
          </CSSTransition>
          <Box
            sx={{
              width: "80%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              marginBottom: 1,
            }}
          >
            <Typography
              sx={{
                fontFamily: "Pretendard",
                fontSize: "1rem",
                fontWeight: "300",
                color: "#7D7A73",
                marginTop: 6,
              }}
            >
              Our Story
            </Typography>
            <Typography
              sx={{
                fontFamily: "Pretendard",
                fontSize: "1.125rem",
                fontWeight: "500",
                color: "black",
                marginTop: 2,
              }}
            >
              당신만을 위한 <br />
              가장 Private한 클리닉, PRIA
            </Typography>
            <Typography
              sx={{
                fontFamily: "Pretendard",
                fontSize: "0.95rem",
                fontWeight: "400",
                color: "#787878",
                marginTop: 2,
                marginBottom: 4,
              }}
            >
              프리아는 오직 당신만을 위한 <br />
              아늑한 공간이 되기를 꿈꾸며 탄생했습니다.
            </Typography>
          </Box>
          <img
            style={{ width: "85%", height: "auto" }}
            key="0"
            src={Interior_entrance}
            alt="lobby"
          />
          <Box
            sx={{
              width: "80%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              marginBottom: 6,
            }}
          >
            <Typography
              sx={{
                fontFamily: "Pretendard",
                fontSize: "1.125rem",
                fontWeight: "500",
                color: "black",
                marginTop: 8,
              }}
            >
              믿을 수 있는 정직함 속에서 <br />
              아름다움이 시작됩니다.
            </Typography>
            <Typography
              sx={{
                fontFamily: "Pretendard",
                fontSize: "0.95rem",
                fontWeight: "400",
                color: "#787878",
                marginTop: 2,
              }}
            >
              당신과의 만남을 소중히 여기며, <br />
              정직한 아름다움을 선사하겠습니다.
            </Typography>
          </Box>
          <Box
            sx={{
              marginTop: 2,
              width: "100%",
              height: "80dvw",
              maxHeight: "600px",
              position: "relative",
            }}
          >
            <img
              style={{
                position: "absolute",
                width: "auto",
                height: "100%",
                right: "10%",
              }}
              src={fourth_interior_img}
              alt="Image"
            />
            <img
              style={{
                position: "absolute",
                width: "50%",
                height: "auto",
                left: "10%",
                top: "18%",
                border: "2px solid white",
              }}
              src={third_interior_img}
              alt="Image"
            />
          </Box>
          <Box
            sx={{
              width: "80%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              marginBottom: 6,
            }}
          >
            <Typography
              sx={{
                fontFamily: "Pretendard",
                fontSize: "0.95rem",
                fontWeight: "400",
                color: "#787878",
                marginTop: 8,
              }}
            >
              프리아는 투명한 가격 정찰제와 함께 <br />
              정품, 정량을 철저하게 지킵니다.
            </Typography>
          </Box>
          {imageUrls.length > 0 &&
            imageUrls.map((image, index) => (
              <Box
                sx={{
                  width: "100%",
                  height: "fit-content",
                  marginBottom: 6,
                  display: "flex",
                  justifyContent: index % 2 === 0 ? "flex-start" : "flex-end",
                }}
              >
                <img
                  style={{ width: "85%", height: "auto" }}
                  key={image.name}
                  src={image.url}
                  alt="Image"
                />
              </Box>
            ))}
          <Box
            sx={{
              width: "80%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              marginBottom: 1,
            }}
          >
            <Typography
              sx={{
                fontFamily: "Pretendard",
                fontSize: "1.125rem",
                fontWeight: "500",
                color: "black",
                marginTop: 6,
              }}
            >
              당신에게 오롯이 집중합니다.
            </Typography>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                marginBottom: 1,
              }}
            >
              <img
                src={diamond_icon}
                alt="diamond"
                style={{
                  width: "25px",
                  height: "25px",
                  marginRight: "16px",
                }}
              />
              <Typography
                sx={{
                  fontFamily: "Pretendard",
                  fontSize: "0.95rem",
                  fontWeight: "400",
                  color: "#787878",
                  marginTop: 1.5,
                }}
              >
                숙련된 의료진의 판단에 따라 개별 고객의
                <br />
                특성을 고려한 1:1 맞춤시술을 제공합니다.
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                marginBottom: 1.5,
              }}
            >
              <img
                src={diamond_icon}
                alt="diamond"
                style={{
                  width: "25px",
                  height: "25px",
                  marginRight: "16px",
                }}
              />
              <Typography
                sx={{
                  fontFamily: "Pretendard",
                  fontSize: "0.95rem",
                  fontWeight: "400",
                  color: "#787878",
                  marginTop: 1.5,
                }}
              >
                객관적인 진단 장비를 활용하여
                <br />
                더욱 과학적인 관리를 제공합니다.
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                marginBottom: 1.5,
              }}
            >
              <img
                src={diamond_icon}
                alt="diamond"
                style={{
                  width: "25px",
                  height: "25px",
                  marginRight: "16px",
                }}
              />
              <Typography
                sx={{
                  fontFamily: "Pretendard",
                  fontSize: "0.95rem",
                  fontWeight: "400",
                  color: "#787878",
                  marginTop: 1.5,
                }}
              >
                불필요한 권유가 아닌, 시술에 대한
                <br />
                정확한 정보전달을 위한 상담을 진행합니다.
              </Typography>
            </Box>
          </Box>
          {/* {imageUrls.map((image) => (
            <img
              style={{ height: "auto", width: "80dvw" }}
              key={image.name}
              src={image.url}
              alt="Image"
            />
          ))} */}
        </Box>

        <Box
          ref={sectionRefs.section2}
          id="section2"
          sx={{
            display: "inline-block",
            width: "100%",
            backgroundColor: "#fff",
            // padding: 2,
          }}
        >
          <Typography
            sx={{
              fontFamily: "Pretendard",
              fontSize: "1.25rem",
              fontWeight: "500",
              marginBottom: "3%",
              marginTop: "120px",
              padding: 2,
            }}
          >
            PRIA의 의료진
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              width: "100%",
              height: "100%",
              paddingBottom: 4,
            }}
          >
            <img
              src={doctor}
              alt="PRIA"
              style={{
                position: "relative",
                width: "40%",
                height: "auto",
              }}
            />
            <Typography
              sx={{
                fontFamily: "Pretendard",
                margin: 2,
                fontSize: "1rem",
                fontWeight: "500",
              }}
            >
              황정원 대표원장
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              width: "100%",
              height: "100%",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Pretendard",
                margin: 3,
                fontSize: "1rem",
                fontWeight: "500",
              }}
            >
              김호중 원장
            </Typography>
            <img
              src={doctor}
              alt="PRIA"
              style={{ width: "40%", height: "auto" }}
            />
          </Box>
        </Box>
        <Box
          ref={sectionRefs.section3}
          id="section3"
          sx={{
            display: "inline-block",
            width: "100%",
            backgroundColor: "#fff",
          }}
        >
          <Box
            sx={{
              display: "inline-block",
              width: "100%",
              backgroundColor: "#fff",
              margin: 0,
              marginTop: "120px",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Pretendard",
                margin: 2,
                fontSize: "1.25rem",
                fontWeight: "500",
              }}
            >
              오시는길
            </Typography>
            <img
              src={way2pria}
              alt="PRIA"
              style={{
                position: "relative",
                width: "100%",
                height: "auto",
              }}
            />
            <Typography
              sx={{
                fontFamily: "Pretendard",
                margin: 2,
                fontSize: "1rem",
                fontWeight: "500",
              }}
            >
              서울시 서초구 강남대로 349 <br />
              우남빌딩 13층 프리아의원
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Pretendard",
                  margin: 1,
                  marginLeft: 2,
                  fontSize: "0.9rem",
                  fontWeight: "500",
                  padding: "6px 8px",
                  backgroundColor: "#f5f5f5",
                  borderRadius: 4,
                  width: "60px",
                  height: "fit-content",
                  display: "flex", // flexbox 활성화
                  alignItems: "center", // 세로 중앙 정렬
                  justifyContent: "center", // 가로 중앙 정렬
                  textAlign: "center", // 텍스트 중앙 정렬
                }}
              >
                지하철
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Pretendard",
                  margin: 0,
                  fontSize: !isNaverBrowser ? "0.75rem" : "0.7rem",
                  fontWeight: "400",
                }}
              >
                (2호선) 강남역 7번 출구 도보 3분 <br />
                (신분당선) 강남역 5번 출구 도보 1분
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Pretendard",
                  margin: 1,
                  marginLeft: 2,
                  fontSize: "0.9rem",
                  fontWeight: "500",
                  padding: "6px 8px",
                  backgroundColor: "#f5f5f5",
                  borderRadius: 4,
                  width: "60px",
                  height: "fit-content",
                  display: "flex", // flexbox 활성화
                  alignItems: "center", // 세로 중앙 정렬
                  justifyContent: "center", // 가로 중앙 정렬
                  textAlign: "center", // 텍스트 중앙 정렬
                }}
              >
                버스
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Pretendard",
                  margin: 0,
                  fontSize: !isNaverBrowser ? "0.75rem" : "0.7rem",
                  fontWeight: "400",
                }}
              >
                정류장 우성아파트사거리, 수협서초지점, <br />
                신분당선강남역, 신분당선・강남역4번출구
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Pretendard",
                  margin: 1,
                  marginLeft: 2,
                  fontSize: "0.9rem",
                  fontWeight: "500",
                  padding: "6px 8px",
                  backgroundColor: "#f5f5f5",
                  borderRadius: 4,
                  width: "60px",
                  height: "fit-content",
                  display: "flex", // flexbox 활성화
                  alignItems: "center", // 세로 중앙 정렬
                  justifyContent: "center", // 가로 중앙 정렬
                  textAlign: "center", // 텍스트 중앙 정렬
                }}
              >
                택시
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Pretendard",
                  margin: 0,
                  fontSize: !isNaverBrowser ? "0.75rem" : "0.75rem",
                  fontWeight: "400",
                }}
              >
                1층에 수협은행이 있습니다.
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                flexWrap: "wrap",
                mb: 3,
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Pretendard",
                  margin: 1,
                  marginLeft: 2,
                  fontSize: "0.9rem",
                  fontWeight: "500",
                  padding: "6px 8px",
                  backgroundColor: "#f5f5f5",
                  borderRadius: 4,
                  width: "60px",
                  height: "fit-content",
                  display: "flex", // flexbox 활성화
                  alignItems: "center", // 세로 중앙 정렬
                  justifyContent: "center", // 가로 중앙 정렬
                  textAlign: "center", // 텍스트 중앙 정렬
                }}
              >
                자가용
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Pretendard",
                  margin: 0,
                  fontSize: !isNaverBrowser ? "0.75rem" : "0.7rem",
                  fontWeight: "400",
                }}
              >
                건물 뒷편으로 오시면 발렛파킹 서비스 가능합니다. <br />
                주차비는 시술 받으신 시간만큼 지원해드리며, <br />
                발렛비는 3000원 별도 발생됩니다.
              </Typography>
            </Box>
          </Box>
          <Box
            ref={sectionRefs.section4}
            id="section4"
            sx={{
              display: "inline-block",
              width: "100%",
              backgroundColor: "white",
            }}
          >
            <Box
              sx={{
                display: "inline-block",
                width: "100%",
                marginTop: "80px",
                marginBottom: 0,
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Pretendard",
                  padding: 1,
                  marginLeft: 2,
                  marginBottom: 0,
                  fontSize: "1.25rem",
                  fontWeight: "500",
                }}
              >
                진료시간
              </Typography>
            </Box>
            <Box sx={{ padding: 0 }}>
              <ul
                style={{
                  padding: "0px 0px 16px 24px",
                  paddingTop: "10px",
                  display: "flex",
                  position: "relative",
                  gap: "8px 16px",
                  flexFlow: "column wrap",
                  alignItems: "flex-start",
                }}
              >
                <li
                  style={{
                    display: "flex",
                    width: "200px",
                    justifyContent: "flex-start",
                  }}
                >
                  <span
                    style={{
                      width: "80px",
                      fontSize: "14px",
                      lineHeight: "22px",
                      fontWeight: "400",
                    }}
                  >
                    평일
                  </span>
                  <span
                    style={{
                      width: "120px",
                      fontSize: "14px",
                      lineHeight: "22px",
                      fontWeight: "400",
                    }}
                  >
                    11:00 - 21:00
                  </span>
                </li>
                <li
                  style={{
                    display: "flex",
                    width: "200px",
                    justifyContent: "flex-start",
                  }}
                >
                  <span
                    style={{
                      width: "80px",
                      fontSize: "14px",
                      lineHeight: "22px",
                      fontWeight: "400",
                    }}
                  >
                    토요일
                  </span>
                  <span
                    style={{
                      width: "120px",
                      fontSize: "14px",
                      lineHeight: "22px",
                      fontWeight: "400",
                    }}
                  >
                    10:00 - 17:00
                  </span>
                </li>
                <li
                  style={{
                    display: "flex",
                    width: "200px",
                    justifyContent: "flex-start",
                  }}
                >
                  <span
                    style={{
                      width: "80px",
                      fontSize: "14px",
                      lineHeight: "22px",
                      fontWeight: "400",
                    }}
                  >
                    공휴일
                  </span>
                  <span
                    style={{
                      width: "120px",
                      fontSize: "14px",
                      lineHeight: "22px",
                      fontWeight: "400",
                    }}
                  >
                    10:00 - 17:00
                  </span>
                </li>
                <li
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "flex-start",
                  }}
                >
                  <span
                    style={{
                      width: "100%",
                      fontSize: "14px",
                      lineHeight: "22px",
                      fontWeight: "400",
                    }}
                  >
                    공휴일 전부 운영 (설, 추석 당일 제외)
                  </span>
                </li>
                <li
                  style={{
                    display: "flex",
                    width: "200px",
                    justifyContent: "flex-start",
                  }}
                >
                  <span
                    style={{
                      width: "80px",
                      fontSize: "14px",
                      lineHeight: "22px",
                      fontWeight: "400",
                    }}
                  >
                    일요일
                  </span>
                  <span
                    style={{
                      width: "120px",
                      fontSize: "14px",
                      lineHeight: "22px",
                      fontWeight: "400",
                    }}
                  >
                    휴진
                  </span>
                </li>
              </ul>
              <Box
                sx={{
                  width: "100%",
                  height: "auto",
                  display: "flex",
                  justifyContent: "center",
                  p: 0,
                }}
              >
                <img
                  src={Interior_logo1}
                  alt="Image"
                  style={{ width: "80%", height: "auto" }}
                />
              </Box>
              <li
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "flex-start",
                  marginTop: "20px",
                  marginBottom: "8px",
                  paddingLeft: "8px",
                }}
              >
                <Link
                  to={`/${language}/marketingpolicy`}
                  style={{ textDecoration: "none" }}
                >
                  <span
                    style={{
                      fontSize: "12px",
                      lineHeight: "22px",
                      fontWeight: "400",
                      color: "black",
                    }}
                  >
                    이용약관
                  </span>
                </Link>
                <span
                  style={{
                    width: "20px",
                    display: "inline-block",
                    fontSize: "12px",
                    lineHeight: "22px",
                    fontWeight: "400",
                    textAlign: "center",
                  }}
                >
                  |
                </span>
                <Link
                  to={`/${language}/policy`}
                  style={{ textDecoration: "none" }}
                >
                  <span
                    style={{
                      fontSize: "12px",
                      lineHeight: "22px",
                      fontWeight: "400",
                      color: "black",
                    }}
                  >
                    개인정보 처리방침
                  </span>
                </Link>
              </li>
            </Box>
          </Box>
          <Box
            sx={{
              display: "inline-block",
              width: "100%",
              backgroundColor: "black",
              padding: 2,
            }}
          >
            <Typography
              sx={{ color: "white", fontSize: "20px", fontWeight: "400" }}
            >
              02-6229-3499
            </Typography>
            <Typography
              sx={{
                color: "white",
                fontSize: "12px",
                fontWeight: "200",
                lineHeight: "22px",
              }}
            >
              상호명: 프리아의원
            </Typography>
            <Typography
              sx={{
                color: "white",
                fontSize: "12px",
                fontWeight: "200",
                lineHeight: "22px",
              }}
            >
              대표자: 황정원
            </Typography>
            <Typography
              sx={{
                color: "white",
                fontSize: "12px",
                fontWeight: "200",
                lineHeight: "22px",
              }}
            >
              주소: 서울특별시 서초구 강남대로349 우남빌딩 13층
            </Typography>
            <Typography
              sx={{
                color: "white",
                fontSize: "12px",
                fontWeight: "200",
                lineHeight: "22px",
              }}
            >
              사업자등록번호: 339-25-01763
            </Typography>
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default Introduction;
