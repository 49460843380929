import {
  ConfirmationResult,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  setPersistence,
  browserSessionPersistence,
  signOut,
  getAuth,
} from "firebase/auth";
import React, { useState, useEffect } from "react";
import "./all_css/Cart.css";
import { auth, onAuthStateChanged } from "../service/firebaseConfig";
import { Box, Typography, Button } from "@mui/material";
import kakaotalkIcon from "./images/kakaotalkIcon.png";
import { User } from "firebase/auth";
import { set } from "react-datepicker/dist/date_utils";
import axios from "axios";

interface AuthenticationProps {
  isVerified: boolean;
  setIsVerified: (query: boolean) => void;
  userName: string;
  setUserName: (query: string) => void;
  phoneNumber: string;
  setPhoneNumber: (query: string) => void;
}

declare global {
  interface Window {
    recaptchaVerifier: any;
    confirmationResult: any;
  }
}

const Authentication: React.FC<AuthenticationProps> = ({
  isVerified,
  setIsVerified,
  userName,
  setUserName,
  phoneNumber,
  setPhoneNumber,
}) => {
  // 본인인증
  const [tempUserName, setTempUserName] = useState<string>("");
  const [tempPhoneNumber, setTempPhoneNumber] = useState<string>("");
  const [verificationCode, setVerificationCode] = useState<string>("");
  const [sentCode, setSentCode] = useState<string>("");
  const [isCodeSent, setIsCodeSent] = useState<boolean>(false);
  const [isCodeVerified, setIsCodeVerified] = useState<boolean>(isVerified);

  // Function to normalize phone number
  const normalizePhoneNumber = (phone: string): string => {
    if (phone.startsWith("01")) {
      return "+82" + phone.slice(1);
    }
    return phone;
  };

  function generateSixDigitString(): string {
    return Math.floor(100000 + Math.random() * 900000)
      .toString()
      .padStart(6, "0");
  }
  async function sendVerificationCode3(userPhoneNum: string, userName: string) {
    const url = "https://us-central1-pria1-5e67a.cloudfunctions.net/sendSMS";
    const authNumber = generateSixDigitString();
    setSentCode(authNumber);
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          key: process.env.REACT_APP_ALIGO_APIKEY,
          user_id: process.env.REACT_APP_ALIGO_USERID,
          sender: "01084973499",
          receiver: userPhoneNum,
          msg: `[프리아의원] 본인인증번호는 [${authNumber}] 입니다.`,
        }),
      });
      const data = await response.json();
      setIsCodeSent(data.message === "success");
    } catch (error) {
      console.log("error while aligo: ", error);
    }
  }

  async function verifyCode3(authNumber: string) {
    if (authNumber === sentCode) {
      setIsCodeVerified(true);
      setIsVerified(true);
      sessionStorage.setItem("verified", "true");
      sessionStorage.setItem("userName", tempUserName);
      sessionStorage.setItem("phoneNumber", tempPhoneNumber);
    } else {
      alert("인증번호가 일치하지 않습니다.");
    }
  }
  return (
    <div style={{ width: "100%", backgroundColor: "white" }}>
      <div id="recaptcha-container"></div>
      <div style={{ position: "relative", left: "5%" }}>
        <h3>본인인증</h3>
      </div>
      <Box justifyContent={"center"} sx={{ width: "100%" }}>
        {!isCodeVerified ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems={"center"}
            gap={2}
            sx={{ width: "100%", left: "10%" }}
          >
            <Box display="flex" flexDirection="row" sx={{ width: "85%" }}>
              <input
                type="text"
                value={tempUserName}
                onChange={(e) => setTempUserName(e.target.value)}
                placeholder="이름"
                style={{ height: 40, width: "100%" }}
                disabled={isCodeSent}
              />
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              gap={2}
              sx={{ width: "85%" }}
            >
              <input
                type="text"
                value={tempPhoneNumber}
                onChange={(e) => setTempPhoneNumber(e.target.value)}
                placeholder="전화번호 (숫자만)"
                style={{ width: "60%", height: 40 }}
                disabled={isCodeSent}
              />
              <Button
                variant="outlined"
                sx={{
                  width: "40%",
                  height: 46,
                  borderColor: "#3f3936",
                  color: "#3f3936",
                }}
                onClick={() => {
                  sendVerificationCode3(tempPhoneNumber, tempUserName);
                  alert(
                    "본인확인 후 인증번호를 전송합니다. 잠시만 기다려주세요!"
                  );
                }}
                disabled={isCodeSent}
              >
                인증요청
              </Button>
            </Box>
            {isCodeSent && (
              <Typography
                sx={{
                  fontFamily: "Pretendard-SemiBold, Helvetica",
                  fontWeight: "500",
                  color: "#3f3936",
                  fontSize: "0.85rem",
                  textAlign: "center",
                }}
              >
                인증번호가 성공적으로 전송되었습니다.
              </Typography>
            )}
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              gap={2}
              sx={{ width: "85%" }}
            >
              <input
                type="text"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                placeholder="인증번호 6자리"
                style={{ width: "60%", height: 40 }}
              />
              <Button
                variant="outlined"
                sx={{
                  width: "40%",
                  height: 46,
                  borderColor: "#3f3936",
                  color: "#3f3936",
                }}
                onClick={() => {
                  verifyCode3(verificationCode);
                  setUserName(tempUserName);
                  setPhoneNumber(tempPhoneNumber);
                  // setIsVerified(true);
                  // verifyCode(verificationCode);
                }}
              >
                확인
              </Button>
            </Box>
            {/* 카톡인증 버튼 */}
            {/* <Button
              variant="contained"
              sx={{
                width: "85%",
                height: 46,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 1,

                backgroundColor: "#ffde68",
                borderRadius: 1,
                border: "1px solid transparent",
                "&:hover": {
                  backgroundColor: "#ffde68",
                },
              }}
              onClick={() => {
                alert("카카오톡 인증은 준비중입니다.");
                // logout();
                // checkAuthState();
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Pretendard-SemiBold, Helvetica",
                  fontWeight: "bold",
                  color: "#3f3936",
                  fontSize: "0.875rem",
                  textAlign: "center",
                  whiteSpace: "nowrap",
                }}
              >
                카카오톡으로 인증하기
              </Typography>
              <img
                src={kakaotalkIcon}
                alt="kakao"
                style={{ width: "20px", height: "20px" }}
              />
            </Button> */}
          </Box>
        ) : (
          <Box
            display="flex"
            flexDirection="column"
            alignItems={"center"}
            gap={2}
            sx={{
              width: "100%",
              height: "70px",
              left: "10%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Pretendard",
                fontWeight: "500",
                color: "#3f3936",
                fontSize: "0.8rem",
                textAlign: "center",
              }}
            >
              본인인증이 완료되었습니다!
              <br />
              예약을 확정해주세요.
            </Typography>
          </Box>
        )}
      </Box>
    </div>
  );
};

export default Authentication;
