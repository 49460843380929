import React, { useState, useEffect } from "react";
import { Box, Grid2 } from "@mui/material";
import { useSwipeable } from "react-swipeable";
import storybanner1_mobile from "./images/storybanner1_mobile.jpg";
import storybanner2_mobile from "./images/storybanner2_mobile.jpg";
import storybanner3_mobile from "./images/storybanner3_mobile.jpg";
import storybanner1_pc from "./images/storybanner1_pc.jpg";
import storybanner2_pc from "./images/storybanner2_pc.jpg";
import storybanner3_pc from "./images/storybanner3_pc.jpg";

const Banner = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const images =
    window.innerWidth > 750
      ? [storybanner1_pc, storybanner2_pc, storybanner3_pc]
      : [storybanner1_mobile, storybanner2_mobile, storybanner3_mobile];

  // 자동 슬라이드
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000); // 5초마다 변경
    return () => clearInterval(interval); // 컴포넌트 언마운트 시 정리
  }, [images.length]);

  // Swipe 이벤트 핸들러
  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => handleNext(),
    onSwipedRight: () => handlePrev(),
  });

  // 다음 이미지로 이동
  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  // 이전 이미지로 이동
  const handlePrev = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  return (
    <Box
      sx={{
        width: "100%",
        overflow: "hidden",
        position: "relative",
      }}
      {...swipeHandlers} // Swipeable 이벤트 연결
    >
      <Grid2
        container
        sx={{
          display: "flex",
          transform: `translateX(-${currentIndex * 100}%)`,
          transition: "transform 0.5s ease-in-out",
          flexWrap: "nowrap",
        }}
      >
        {images.map((image, index) => (
          //   <Box
          //     key={index}
          //     sx={{
          //       width: "100%",
          //       height: "fit-content", // 원하는 높이 설정
          //       backgroundImage: `url(${image})`,
          //       backgroundSize: "cover",
          //       backgroundPosition: "center",
          //     }}
          //   />
          <img
            src={image}
            key={index}
            style={{
              minWidth: "100%",
              width: "100%",
              height: "auto",
              cursor: "pointer",
            }}
          />
        ))}
      </Grid2>
      {/* 좌/우 이동 버튼 */}
      {/* <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "10px",
          transform: "translateY(-50%)",
          cursor: "pointer",
        }}
        onClick={handlePrev}
      >
        &#9664;
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          right: "10px",
          transform: "translateY(-50%)",
          cursor: "pointer",
        }}
        onClick={handleNext}
      >
        &#9654;
      </Box> */}
    </Box>
  );
};

export default Banner;
