import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";

const Marketingpolicy: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Box sx={{ padding: 3, fontFamily: "Pretendard" }}>
        <Typography
          variant="h5"
          sx={{ fontWeight: "bold", marginBottom: 2, fontFamily: "Pretendard" }}
        >
          광고 및 마케팅 수신 동의 약관
        </Typography>
        <Typography variant="body1" sx={{ fontFamily: "Pretendard" }}>
          <strong>광고 및 마케팅 수신 동의 약관</strong>
          <br />
          『프리아의원』은(이하 ‘의원’ 이라 함) 개인정보 보호법 제22조 제4항과
          제39조의 3에 따라 사용자의 광고성 정보 수신과 이에 따른 개인정보
          처리에 대한 동의를 받고 있습니다. 약관에 동의하지 않으셔도 케미의 모든
          서비스를 이용하실 수 있습니다. 다만 이벤트, 혜택 등의 제한이 있을 수
          있습니다.
          <br />
          <strong>개인정보 수집 항목</strong>
          <br />
          이름, 휴대폰 번호, 성별, 생년월일
          <br />
          <br />
          <strong>개인정보 수집 이용 목적</strong>
          <br />
          이벤트 운영 및 광고성 정보 전송
          <br />
          서비스 관련 정보 전송
          <br />
          <br />
          <strong>보유 및 이용 기간</strong>
          <br />
          동의 철회 시 또는 운영 중단 시까지
          <br />
          <br />
          <strong>동의 철회 방법</strong>
          <br />
          의원으로 문의
          <br />
          이메일: backupmucho@gmail.com
          <br />
          <br />
          <strong>전송 방법</strong>
          <br />
          핸드폰 문자메시지(SMS) 등
          <br />
          <br />
          <strong>전송 내용</strong>
          <br />
          혜택 정보, 이벤트 정보, 상품 정보, 신규 시술 안내 등의 광고성 정보제공
          <br />
          <br />
          공고일자: 2024년 12월 23일
          <br />
          시행일자: 2024년 12월 23일
        </Typography>
      </Box>
      <Box
        sx={{
          display: "inline-block",
          width: "100%",
          backgroundColor: "black",
          padding: 2,
        }}
      >
        <Typography
          sx={{ color: "white", fontSize: "20px", fontWeight: "400" }}
        >
          02-6229-3499
        </Typography>
        <Typography
          sx={{
            color: "white",
            fontSize: "12px",
            fontWeight: "200",
            lineHeight: "22px",
          }}
        >
          상호명: 프리아의원
        </Typography>
        <Typography
          sx={{
            color: "white",
            fontSize: "12px",
            fontWeight: "200",
            lineHeight: "22px",
          }}
        >
          대표자: 황정원
        </Typography>
        <Typography
          sx={{
            color: "white",
            fontSize: "12px",
            fontWeight: "200",
            lineHeight: "22px",
          }}
        >
          주소: 서울특별시 서초구 강남대로349 우남빌딩 13층
        </Typography>
        <Typography
          sx={{
            color: "white",
            fontSize: "12px",
            fontWeight: "200",
            lineHeight: "22px",
          }}
        >
          사업자등록번호: 339-25-01763
        </Typography>
      </Box>
    </div>
  );
};

export default Marketingpolicy;
