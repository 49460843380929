import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";

const Policy: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Box sx={{ padding: 3, fontFamily: "Pretendard" }}>
        <Typography
          variant="h5"
          sx={{ fontWeight: "bold", marginBottom: 2, fontFamily: "Pretendard" }}
        >
          개인정보 처리방침
        </Typography>
        <Typography variant="body1" paragraph sx={{ fontFamily: "Pretendard" }}>
          『프리아의원』은(이하 ‘의원’이라 함) 귀하의 개인정보보호를 매우
          중요시하며,
          <strong> [개인정보보호법]</strong>을 준수하고 있습니다. 병원은
          개인정보처리방침을 통하여 귀하께서 제공하시는 개인정보가 어떠한 용도와
          방식으로 이용되고 있으며 개인정보를 위해 어떠한 조치가 취해지고 있는지
          알려드립니다.
        </Typography>

        <Typography variant="body1" sx={{ fontFamily: "Pretendard" }}>
          1. 수집하는 개인정보의 항목 및 수집방법
          <br />
          2. 개인정보의 수집 및 이용 목적
          <br />
          3. 개인정보의 보유 및 이용기간 및 파기절차 및 파기방법
          <br />
          4. 이용자 및 법정대리인의 권리와 그 행사방법
          <br />
          5. 동의철회 방법
          <br />
          6. 개인정보의 제공 및 위탁
          <br />
          7. 개인정보의 제3자 제공 및 위탁
          <br />
          8. 개인정보 보호책임자
          <br />
          9. 개인정보의 안전성 확보조치
          <br />
          10. 정책 변경에 따른 공지의무
        </Typography>
        <Typography variant="body2" sx={{ fontFamily: "Pretendard" }}>
          <strong>1. 수집하는 개인정보의 항목 및 수집방법</strong>
          <br />
          의원은 인터넷 회원가입 및 예약 시 서비스 이용을 위해 필요한 최소한의
          개인정보만을 수집합니다. 진료를 위한 필수정보는 의료법에 따라 별도의
          동의 없이 진료카드 작성을 통해 수집하며, 진료 외의 추가
          서비스(광고성)를 위해서는 별도의 수집·이용 동의서를 작성하셔야 하며,
          동의서를 작성하지 않더라도 진료에는 전혀 불이익이 없습니다.
          <br />
          - 필수항목: 성명, 생년월일, 성별, 주소, 연령, 연락처, 휴대전화,
          이메일, 병원등록번호, 서비스신청여부
          <br />
          - 건강정보: 내원정보, 상병정보, 처방정보, 진료정보
          <br />
          - 수납정보: 카드사명, 카드번호 등 카드결제 승인정보
          <br />
          <br />
          <strong>2. 개인정보의 수집 및 이용 목적</strong>
          <br />
          ① 필수
          <br />
          - 진료정보: 진단 및 치료를 위한 진료서비스와 청구, 수납 및 환급 등의
          원무 서비스 제공
          <br />
          - 예약정보: 진료 예약 및 예약조회 등 기타 서비스 이용에 따른 본인 확인
          절차에 이용 문자 및 SNS를 통한 병원소식, 질병정보 등의 안내, 설문조사
          <br />
          - 기타: 신규 서비스 개발을 위한 방문 고객 분석자료 작성에 이용 외부
          수탁검사 의뢰를 위한 기초자료
          <br />
          - 소비자 기본법 제52조에 의거한 소비자 위해 정보 수집
          <br />
          - 법령 및 의원 이용약관을 위반하는 이용자에 대한 이용 제한 조치, 부정
          이용 행위를 포함하여 서비스의 원활한 운영에 지장을 주는 행위에 대한
          방지 및 제재, 개인정보 도용 및 부정사용 방지, 분쟁조정을 위한 기록
          보존, 민원처리 등<br />
          ② 선택
          <br />
          - 마케팅 및 홍보: 병원 소식, 병원 시술 및 이벤트, 화장품 등 광고성
          정보 전달
          <br />
          <br />
          <strong>
            3. 개인정보의 보유 및 이용기간 및 파기절차 및 파기방법
          </strong>
          <br />
          [보유 및 이용기간]
          <br />
          - 의원은 수집된 고객의 개인정보를 환자의 명부 5년, 진료기록부를
          보관하는 법정 기간(10년)동안만 보유하며 그 이후는 DB에서 삭제. 다만,
          채권·채무관계 잔존 시에는 해당 채권·채무관계 정산 시까지
          <br />
          - 정보제공자가 개인정보 삭제를 요청할 경우 즉시 삭제
          <br />
          단, 수집 목적 또는 제공받는 목적이 달성된 경우에도 상법 등 기타 법령의
          구정에 다라 보존할 필요성이 있는 경우, 개인정보를 보유할 수 있음
          <br />
          * 소비자의 불만 또는 분쟁처리에 관한 기록: 3년(전자상거래 등에서의
          소비자보호에 관한 법률)
          <br />
          * 신용정보의 수집/처리 및 이용 등에 관한 기록: 3년(신용정보의 이용 및
          보호에 관한 법률)
          <br />
          * 본인 확인에 관한 기록: 6개월 (정보통신망 이용촉진 및 정보보호 등에
          관한 법률)
          <br />
          <br />
          [파기절차 및 파기방법]
          <br />
          - 파기절차: 법정 보유기간 후 파기방법에 의하여 즉시 파기
          <br />
          - 파기방법: 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는
          기술적 방법을 사용하여 삭제하고 종이에 출력된 개인정보는 분쇄기로
          분쇄하거나 소각하여 파기
          <br />
          - 고객관리 서비스 위탁 종료 시: 수탁자는 2주 이내에 관련파일 및
          DB데이터의 영구적 파기
          <br />
          <br />
          <strong>
            4. 개인정보의 자동 수집 장치의 설치/운영 및 그 거부에 관한 사항
          </strong>
          <br />
          상기 개인 정보 외에 서비스는 쿠키(Cookie)와 픽셀 태그(Pixel Tag) 또는
          웹 비콘(Web Beacon) 및 IP 을 통해 접속자를 인식하며 아래의 목적으로
          사용합니다. 프리아의원 웹사이트와 서비스를 사용할 경우 이러한 쿠키
          등의 기술을 사용하는 데 동의하는 것으로 간주됩니다.
          <br />
          - 예: 쿠키 설정을 거부하는 방법으로는 회원님이 사용하시는 웹
          브라우저의 옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할
          때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.
          설정방법 예(인터넷 익스플로러의 경우): 웹 브라우저 상단의 도구 -
          인터넷 옵션 - 개인정보 단, 귀하께서 쿠키 설치를 거부하였을 경우 서비스
          제공에 어려움이 있을 수 있습니다.
          <br />
          <br />
          <strong>5. 이용자 및 법정대리인의 권리와 그 행사방법</strong>
          <br />
          미성년자의 예약은 미성년자가 이해하기 쉬운 평이한 표현으로 작성된
          별도의 양식을 통해 이루어지고 있으며 개인정보 수집 시 반드시
          법정대리인의 동의를 구하고 있습니다. 의원은 법정대리인의 동의를 받기
          위하여 미성년자로부터 법정대리인의 성명과 연락처 등 최소한의 정보를
          수집하고 있으며, 개인정보취급방침에서 규정하고 있는 방법에 따라
          법정대리인의 동의를 받고 있습니다.
          <br />
          <br />
          <strong>6. 동의철회 방법</strong>
          <br />
          귀하는 온라인예약 시 선택사항의 개인정보의 수집·이용 및 제공에 대해
          동의하신 내용을 언제든지 철회하실 수 있습니다. 개인정보보호책임자로
          서면, 전화 또는 Fax 등으로 연락하시면 지체 없이 귀하의 개인정보를
          파기하는 등 필요한 조치를 하겠습니다.
          <br />
          <br />
          <strong>7. 개인정보의 제공 및 공유</strong>
          <br />
          의원은 귀하의 동의가 있거나 관련법령의 규정에 의한 경우를 제외하고는
          어떠한 경우에도 개인정보의 수집 및 이용목적에서 고지한 범위를 넘어
          귀하의 개인정보를 이용하거나 타인 또는 타기업ㆍ기관에 제공하지
          않습니다.
          <br />
          <br />
          <strong>8. 개인정보의 제3자 제공 및 위탁</strong>
          <br />
          의원은 인터넷예약 및 문자 알림서비스 업무를 아래와 같이 개인정보를
          위탁하고 있으며, 관계 법령에 따라 위탁계약 시 개인정보가 안전하게
          관리될 수 있도록 필요한 사항을 규정하고 있습니다.
          <br />
          <br />
          <strong>9. 개인정보보호 책임자</strong>
          <br />
          이름: 황정원
          <br />
          직위: 프리아의원 대표원장
          <br />
          소속: 프리아의원
          <br />
          이메일: backupmucho@gmail.com
          <br />
          <br />
          <strong>10. 개인정보의 안전성 확보조치</strong>
          <br />
          의원은 이용자의 개인정보보호를 위한 기술적 대책으로서 여러 보안장치를
          마련하고 있습니다. 이용자께서 제공하신 모든 정보는 방화벽 등
          보안장비에 의해 안전하게 보호/관리되고 있습니다.
          <br />
          <br />
        </Typography>
      </Box>
      <Box
        sx={{
          display: "inline-block",
          width: "100%",
          backgroundColor: "black",
          padding: 2,
        }}
      >
        <Typography
          sx={{ color: "white", fontSize: "20px", fontWeight: "400" }}
        >
          02-6229-3499
        </Typography>
        <Typography
          sx={{
            color: "white",
            fontSize: "12px",
            fontWeight: "200",
            lineHeight: "22px",
          }}
        >
          상호명: 프리아의원
        </Typography>
        <Typography
          sx={{
            color: "white",
            fontSize: "12px",
            fontWeight: "200",
            lineHeight: "22px",
          }}
        >
          대표자: 황정원
        </Typography>
        <Typography
          sx={{
            color: "white",
            fontSize: "12px",
            fontWeight: "200",
            lineHeight: "22px",
          }}
        >
          주소: 서울특별시 서초구 강남대로349 우남빌딩 13층
        </Typography>
        <Typography
          sx={{
            color: "white",
            fontSize: "12px",
            fontWeight: "200",
            lineHeight: "22px",
          }}
        >
          사업자등록번호: 339-25-01763
        </Typography>
      </Box>
    </div>
  );
};

export default Policy;
