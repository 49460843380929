import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./all_css/Home.css";
import SearchModule from "./function_components/SearchModule";
import HorizontalProcedure from "./HorizontalProcedure";
import { Box, Typography, CardMedia } from "@mui/material";
import shinee from "./images/shinee.png";
import way2pria from "./images/way2pria.png";
import edge_background from "./images/edge_background.png";
import edge_background_750 from "./images/wide_background1.jpeg";
import white_text_logo_no_background from "./images/PriaLogo_white_no_background.png";
import { useLanguage } from "../LanguageContext";
import upload from "../service/upload";
import Banner from "./Banner";
import EventBanner from "./EventBanner";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";

interface HomeProps {
  setSearchQuery: (query: string) => void;
  setSearchOpen: (open: boolean) => void;
  setIsCouncelPopupOpen: (open: boolean) => void;
}

const Home: React.FC<HomeProps> = ({
  setSearchQuery,
  setSearchOpen,
  setIsCouncelPopupOpen,
}) => {
  const { language } = useLanguage(); // 현재 언어 상태 가져오기
  const [isNaverBrowser, setIsNaverBrowser] = useState(false);
  const navigate = useNavigate();
  const locales = {
    ko: {
      reservation: "시술 예약",
      event: "이벤트",
      consult: "상담하기",
    },
    en: {
      reservation: "Reservation",
      event: "Event",
      consult: "Consult",
    },
    jp: {
      reservation: "ご予約",
      event: "キャンペーン",
      consult: "相談",
    },
  };
  type localeKey = keyof typeof locales;
  const langkey: localeKey = language as localeKey;
  const { reservation, event, consult } = locales[langkey];

  useEffect(() => {
    window.scrollTo(0, 0);
    const userAgent = navigator.userAgent || "";
    if (userAgent.includes("NAVER")) {
      setIsNaverBrowser(true);
    }
  }, []);
  return (
    <div style={{ position: "relative", width: "100%" }}>
      <Box
        sx={{
          position: "relative",
          width: "100%",
          top: -90,
        }}
      >
        <CardMedia
          component="img"
          sx={{
            width: "100%",
            objectFit: "cover",
            objectPosition: "top",
            position: "absolute",
            mixBlendMode: "darken",
          }}
          image={
            window.innerWidth > 750 ? edge_background_750 : edge_background
          }
          alt="Background"
        />
        <Box
          sx={{
            position: "relative",
            width: "100%",
            top: 90,
          }}
        >
          <img
            src={white_text_logo_no_background}
            style={{
              position: "relative",
              width: "36%",
              maxWidth: "180px",
              height: "auto",
              objectFit: "cover",
              left: "6%",
              top: 30,
            }}
          />
          <Typography
            sx={{
              position: "relative",
              top: 30,
              left: "6%",
              fontFamily: "Pretendard",
              fontWeight: "300",
              color: "white",
              fontSize: "1.6rem",
              "@media (max-width: 750px)": {
                fontSize: "1.6rem", // 모바일 모드 글자 크기
              },
              marginBottom: 3,
            }}
          >
            Private for you
          </Typography>
        </Box>
        <Box
          sx={{
            position: "relative",
            width: "100%",
            height: "calc(var(--vh, 1vh) * 50)",
            top: 90,
          }}
        >
          <Box
            sx={{
              position: "absolute",
              width: "44%",
              height: "60%",
              top: "8%",
              left: "6%",
              bgcolor: "#00000080",
              borderRadius: 2,
              overflow: "hidden",
              boxShadow: "0px 0px 36px #0000004c",
              backdropFilter: "blur(8px) brightness(100%)",
            }}
          >
            <Link to={`/${language}/reservation`}>
              <Box
                sx={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  src={shinee}
                  alt="dot"
                  style={{
                    position: "absolute",
                    right: "15%",
                    top: "0%",
                    width: "30px",
                    height: "auto",
                  }}
                />
                <img
                  src={`${process.env.PUBLIC_URL}/RUarrow.png`}
                  alt="GoTo"
                  style={{
                    position: "absolute",
                    bottom: "5%",
                    right: "8%",
                    width: "60px",
                    height: "60px",
                  }}
                />
                <Typography
                  sx={{
                    position: "absolute",
                    top: 25,
                    left: "10%",
                    fontFamily: "Pretendard",
                    fontWeight: "500",
                    color: "white",
                    fontSize: "1.25rem",
                    "@media (max-width: 750px)": {
                      fontSize: "1rem", // 모바일 모드 글자 크기
                    },
                    lineHeight: "normal",
                    whiteSpace: "nowrap",
                  }}
                >
                  {reservation}
                </Typography>
              </Box>
            </Link>
          </Box>
          <Box
            sx={{
              position: "absolute",
              width: "39%",
              height: "35%",
              top: "8%",
              right: "6%",
              background: "rgba(80, 80, 80, 0.30)",
              bgcolor: "#5050504c",
              borderRadius: 2,
              overflow: "hidden",
              boxShadow: "0px 0px 20px #00000080",
              backdropFilter: "blur(8px) brightness(100%)",
            }}
          >
            <Link to={`/${language}/event`}>
              <Box
                sx={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                }}
              >
                <Typography
                  sx={{
                    position: "absolute",
                    top: 25,
                    left: "10%",
                    fontFamily: "Pretendard",
                    fontWeight: "500",
                    color: "white",
                    fontSize: "1.25rem",
                    "@media (max-width: 750px)": {
                      fontSize: "1rem", // 모바일 모드 글자 크기
                    },
                    lineHeight: "normal",
                    whiteSpace: "nowrap",
                  }}
                >
                  {event}
                </Typography>
                <img
                  src={`${process.env.PUBLIC_URL}/diaButton.png`}
                  alt="GoTo"
                  style={{
                    position: "absolute",
                    bottom: "8%",
                    right: "8%",
                    width: "50px",
                    height: "50px",
                  }}
                />
              </Box>
            </Link>
          </Box>
          <Box
            sx={{
              position: "absolute",
              width: "39%",
              height: "22%",
              top: "46%",
              right: "6%",
              bgcolor: "#6262624c",
              borderRadius: 2,
              overflow: "hidden",
              boxShadow: "12px 12px 36px #0000004c",
              backdropFilter: "blur(8px) brightness(100%)",
              cursor: "pointer",
            }}
          >
            <Box
              sx={{
                position: "relative",
                width: "100%",
                height: "100%",
              }}
              onClick={() => setIsCouncelPopupOpen(true)}
            >
              <Typography
                sx={{
                  position: "absolute",
                  top: 20,
                  left: "10%",
                  fontFamily: "Pretendard",
                  fontWeight: "500",
                  color: "white",
                  fontSize: "1.25rem",
                  "@media (max-width: 750px)": {
                    fontSize: "1rem", // 모바일 모드 글자 크기
                  },
                  lineHeight: "normal",
                  whiteSpace: "nowrap",
                }}
              >
                {consult}
              </Typography>
              <img
                src={`${process.env.PUBLIC_URL}/consultIcon.png`}
                alt="GoTo"
                style={{
                  position: "absolute",
                  bottom: "15%",
                  right: "8%",
                  width: "50px",
                  height: "50px",
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              position: "relative",
              width: "88%",
              height: "17%",
              top: "72%",
              left: "6%",
              borderRadius: 2,
              overflow: "hidden",
              bgcolor: "rgba(94, 88, 68, 0.61)",
              boxShadow: "0px 0px 36px #0000004c",
              backdropFilter: "blur(8px) brightness(100%)",
              cursor: "pointer",
            }}
          >
            <Box
              sx={{
                position: "relative",
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              onClick={() => navigate(`/${language}/reservation?category=15`)}
            >
              <Typography
                sx={{
                  position: "relative",
                  left: "5%",
                  fontFamily: "Pretendard",
                  fontWeight: "500",
                  color: "white",
                  fontSize: "1.25rem",
                  "@media (max-width: 750px)": {
                    fontSize: "0.9rem", // 모바일 모드 글자 크기
                  },
                  lineHeight: "normal",
                  whiteSpace: "nowrap",
                }}
              >
                뭘 해야될지 모르겠을 때!&#128514;
              </Typography>
              <QuestionMarkIcon
                sx={{
                  position: "relative",
                  backgroundColor: "white",
                  right: "4%",
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                }}
              />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            position: "relative",
            top: "70px",
            left: "50%",
            transform: "translate(-50%, 0)",
            width: "88%",
            backgroundColor: "white",
            borderRadius: 2,
            boxShadow: 3,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: 2,
            }}
          >
            <Typography
              fontFamily="Pretendard"
              color="textPrimary"
              fontWeight="500"
              fontSize="1rem"
              sx={{ textAlign: "left" }}
            >
              궁금한 시술을 검색해보세요
            </Typography>
            <SearchModule
              setSearchQuery={setSearchQuery}
              setSearchOpen={setSearchOpen}
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: "fit-content",
          marginTop: 8,
          marginBottom: 12,
          top: -20,
        }}
      >
        <Link to={`/${language}/introduction`}>
          <Banner />
        </Link>
      </Box>
      <Box
        sx={{
          position: "relative",
          top: -20,
          width: "100%",
          marginBottom: 8,
        }}
      >
        <Typography
          sx={{
            fontFamily: "Pretendard",
            paddingLeft: "16px",
            margin: 0,
            fontSize: "1.125rem",
            fontWeight: "500",
          }}
        >
          프리아의 시그니처 시술
        </Typography>
        <HorizontalProcedure />
      </Box>
      <Box
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "fit-content",
          marginTop: 8,
          marginBottom: 8,
          top: -20,
        }}
      >
        <Typography
          sx={{
            fontFamily: "Pretendard",
            paddingLeft: "16px",
            margin: 0,
            marginBottom: 2,
            fontSize: "1.125rem",
            fontWeight: "500",
          }}
        >
          프리아의 이벤트
        </Typography>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <EventBanner />
        </Box>
      </Box>
      <Box
        sx={{
          display: "inline-block",
          width: "100%",
          backgroundColor: "#fff",
          margin: 0,
        }}
      >
        <Typography
          sx={{
            fontFamily: "Pretendard",
            margin: 2,
            fontSize: "1.125rem",
            fontWeight: "500",
          }}
        >
          오시는길
        </Typography>
        <img
          src={way2pria}
          alt="PRIA"
          style={{
            position: "relative",
            width: "100%",
            height: "auto",
          }}
        />
        <Typography
          sx={{
            fontFamily: "Pretendard",
            margin: 2,
            fontSize: "1rem",
            fontWeight: "500",
          }}
        >
          서울시 서초구 강남대로 349 <br />
          우남빌딩 13층 프리아의원
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Pretendard",
              margin: 1,
              marginLeft: 2,
              fontSize: "0.9rem",
              fontWeight: "500",
              padding: "6px 8px",
              backgroundColor: "#f5f5f5",
              borderRadius: 4,
              width: "60px",
              height: "fit-content",
              display: "flex", // flexbox 활성화
              alignItems: "center", // 세로 중앙 정렬
              justifyContent: "center", // 가로 중앙 정렬
              textAlign: "center", // 텍스트 중앙 정렬
            }}
          >
            지하철
          </Typography>
          <Typography
            sx={{
              fontFamily: "Pretendard",
              margin: 0,
              fontSize: !isNaverBrowser ? "0.75rem" : "0.7rem",
              fontWeight: "400",
            }}
          >
            (2호선) 강남역 7번 출구 도보 3분 <br />
            (신분당선) 강남역 5번 출구 도보 1분
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Pretendard",
              margin: 1,
              marginLeft: 2,
              fontSize: "0.9rem",
              fontWeight: "500",
              padding: "6px 8px",
              backgroundColor: "#f5f5f5",
              borderRadius: 4,
              width: "60px",
              height: "fit-content",
              display: "flex", // flexbox 활성화
              alignItems: "center", // 세로 중앙 정렬
              justifyContent: "center", // 가로 중앙 정렬
              textAlign: "center", // 텍스트 중앙 정렬
            }}
          >
            버스
          </Typography>
          <Typography
            sx={{
              fontFamily: "Pretendard",
              margin: 0,
              fontSize: !isNaverBrowser ? "0.75rem" : "0.7rem",
              fontWeight: "400",
            }}
          >
            정류장 우성아파트사거리, 수협서초지점, <br />
            신분당선강남역, 신분당선・강남역4번출구
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Pretendard",
              margin: 1,
              marginLeft: 2,
              fontSize: "0.9rem",
              fontWeight: "500",
              padding: "6px 8px",
              backgroundColor: "#f5f5f5",
              borderRadius: 4,
              width: "60px",
              height: "fit-content",
              display: "flex", // flexbox 활성화
              alignItems: "center", // 세로 중앙 정렬
              justifyContent: "center", // 가로 중앙 정렬
              textAlign: "center", // 텍스트 중앙 정렬
            }}
          >
            택시
          </Typography>
          <Typography
            sx={{
              fontFamily: "Pretendard",
              margin: 0,
              fontSize: !isNaverBrowser ? "0.75rem" : "0.75rem",
              fontWeight: "400",
            }}
          >
            1층에 수협은행이 있습니다.
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            flexWrap: "wrap",
            mb: 3,
          }}
        >
          <Typography
            sx={{
              fontFamily: "Pretendard",
              margin: 1,
              marginLeft: 2,
              fontSize: "0.9rem",
              fontWeight: "500",
              padding: "6px 8px",
              backgroundColor: "#f5f5f5",
              borderRadius: 4,
              width: "60px",
              height: "fit-content",
              display: "flex", // flexbox 활성화
              alignItems: "center", // 세로 중앙 정렬
              justifyContent: "center", // 가로 중앙 정렬
              textAlign: "center", // 텍스트 중앙 정렬
            }}
          >
            자가용
          </Typography>
          <Typography
            sx={{
              fontFamily: "Pretendard",
              margin: 0,
              fontSize: !isNaverBrowser ? "0.75rem" : "0.7rem",
              fontWeight: "400",
            }}
          >
            건물 뒷편으로 오시면 발렛파킹 서비스 가능합니다. <br />
            주차비는 시술 받으신 시간만큼 지원해드리며, <br />
            발렛비는 3000원 별도 발생됩니다.
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "inline-block",
          width: "100%",
          backgroundColor: "#f5f5f5",
          padding: 2,
        }}
      >
        <Typography
          sx={{
            fontFamily: "Pretendard",
            marginBottom: "10px",
            fontSize: "1.25rem",
            fontWeight: "500",
          }}
        >
          진료시간
        </Typography>
        <Box>
          <ul
            style={{
              padding: 0,
              paddingTop: "10px",
              display: "flex",
              position: "relative",
              gap: "8px 16px",
              flexFlow: "column wrap",
              alignItems: "flex-start",
            }}
          >
            <li
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-start",
              }}
            >
              <span
                style={{
                  width: "80px",
                  fontSize: "14px",
                  lineHeight: "22px",
                  fontWeight: "400",
                }}
              >
                평일
              </span>
              <span
                style={{
                  width: "100%",
                  fontSize: "14px",
                  lineHeight: "22px",
                  fontWeight: "400",
                }}
              >
                11:00 - 21:00
              </span>
            </li>
            <li
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-start",
              }}
            >
              <span
                style={{
                  width: "80px",
                  fontSize: "14px",
                  lineHeight: "22px",
                  fontWeight: "400",
                }}
              >
                토요일
              </span>
              <span
                style={{
                  width: "100%",
                  fontSize: "14px",
                  lineHeight: "22px",
                  fontWeight: "400",
                }}
              >
                10:00 - 17:00
              </span>
            </li>
            <li
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-start",
              }}
            >
              <span
                style={{
                  width: "80px",
                  fontSize: "14px",
                  lineHeight: "22px",
                  fontWeight: "400",
                }}
              >
                공휴일
              </span>
              <span
                style={{
                  width: "100%",
                  fontSize: "14px",
                  lineHeight: "22px",
                  fontWeight: "400",
                }}
              >
                10:00 - 17:00
              </span>
            </li>
            <li
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-start",
              }}
            >
              <span
                style={{
                  width: "100%",
                  fontSize: "14px",
                  lineHeight: "22px",
                  fontWeight: "400",
                }}
              >
                공휴일 전부 운영 (설, 추석 당일 제외)
              </span>
            </li>
            <li
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-start",
              }}
            >
              <span
                style={{
                  width: "80px",
                  fontSize: "14px",
                  lineHeight: "22px",
                  fontWeight: "400",
                }}
              >
                일요일
              </span>
              <span
                style={{
                  width: "100%",
                  fontSize: "14px",
                  lineHeight: "22px",
                  fontWeight: "400",
                }}
              >
                휴진
              </span>
            </li>
            <li
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-start",
                marginTop: "20px",
              }}
            >
              <Link
                to={`/${language}/marketingpolicy`}
                style={{ textDecoration: "none" }}
              >
                <span
                  style={{
                    fontSize: "12px",
                    lineHeight: "22px",
                    fontWeight: "400",
                    color: "black",
                  }}
                >
                  이용약관
                </span>
              </Link>
              <span
                style={{
                  width: "20px",
                  display: "inline-block",
                  fontSize: "12px",
                  lineHeight: "22px",
                  fontWeight: "400",
                  textAlign: "center",
                }}
              >
                |
              </span>
              <Link
                to={`/${language}/policy`}
                style={{ textDecoration: "none" }}
              >
                <span
                  style={{
                    fontSize: "12px",
                    lineHeight: "22px",
                    fontWeight: "400",
                    color: "black",
                  }}
                >
                  개인정보 처리방침
                </span>
              </Link>
              {/* <Link to={"/upload"} style={{ textDecoration: "none" }}>
                <span
                  style={{
                    fontSize: "12px",
                    lineHeight: "22px",
                    fontWeight: "400",
                    color: "black",
                  }}
                >
                  upload
                </span>
              </Link> */}
            </li>
          </ul>
        </Box>
      </Box>
      <Box
        sx={{
          display: "inline-block",
          width: "100%",
          backgroundColor: "black",
          padding: 2,
        }}
      >
        <Typography
          sx={{ color: "white", fontSize: "20px", fontWeight: "400" }}
        >
          02-6229-3499
        </Typography>
        <Typography
          sx={{
            color: "white",
            fontSize: "12px",
            fontWeight: "200",
            lineHeight: "22px",
          }}
        >
          상호명: 프리아의원
        </Typography>
        <Typography
          sx={{
            color: "white",
            fontSize: "12px",
            fontWeight: "200",
            lineHeight: "22px",
          }}
        >
          대표자: 황정원
        </Typography>
        <Typography
          sx={{
            color: "white",
            fontSize: "12px",
            fontWeight: "200",
            lineHeight: "22px",
          }}
        >
          주소: 서울특별시 서초구 강남대로349 우남빌딩 13층
        </Typography>
        <Typography
          sx={{
            color: "white",
            fontSize: "12px",
            fontWeight: "200",
            lineHeight: "22px",
          }}
        >
          사업자등록번호: 339-25-01763
        </Typography>
      </Box>
    </div>
  );
};

export default Home;
