import React, { useState, useEffect } from "react";
import { Box, Grid2 } from "@mui/material";
import { useSwipeable } from "react-swipeable";
import { useNavigate } from "react-router-dom";
import Eventbanner_2 from "./images/Eventbanner_2.jpg";
import Eventbanner_3 from "./images/Eventbanner_3.jpg";
import Eventbanner_4 from "./images/Eventbanner_4.jpg";
import Eventbanner_5 from "./images/Eventbanner_5.jpg";

const EventBanner = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const images = [Eventbanner_2, Eventbanner_3, Eventbanner_4, Eventbanner_5];
  const links = ["6", "1", "10", "4"];
  const navigate = useNavigate();
  // 자동 슬라이드
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000); // 5초마다 변경
    return () => clearInterval(interval); // 컴포넌트 언마운트 시 정리
  }, [images.length]);

  // Swipe 이벤트 핸들러
  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => handleNext(),
    onSwipedRight: () => handlePrev(),
  });

  // 다음 이미지로 이동
  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  // 이전 이미지로 이동
  const handlePrev = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  return (
    <Box
      sx={{
        width: window.innerWidth > 750 ? "80%" : "90%",
        overflow: "hidden",
        position: "relative",
      }}
      {...swipeHandlers} // Swipeable 이벤트 연결
    >
      <Grid2
        container
        sx={{
          display: "flex",
          transform: `translateX(-${currentIndex * 100}%)`,
          transition: "transform 0.5s ease-in-out",
          flexWrap: "nowrap",
        }}
      >
        {images.map((image, index) => (
          <img
            src={image}
            key={index}
            style={{
              minWidth: "100%",
              width: "100%",
              height: "auto",
              cursor: "pointer",
            }}
            onClick={() => navigate(`/ko/reservation?category=${links[index]}`)}
          />
        ))}
      </Grid2>
    </Box>
  );
};

export default EventBanner;
