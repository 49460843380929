import React, { useState, memo } from "react";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { createPortal } from "react-dom";
import { useLanguage } from "../LanguageContext";

const CartAddedPopup = (): JSX.Element => {
  const { language } = useLanguage(); // 현재 언어 상태 가져오기
  return (
    // const CartAddedPopup = ({
    //   isOpen,
    //   onClose,
    // }: {
    //   isOpen: boolean;
    //   onClose: () => void;
    // }) => {
    //   const { language } = useLanguage(); // 현재 언어 상태 가져오기

    //   if (!isOpen) return null;
    //   else
    //     return createPortal(
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.6)",
        borderRadius: 2,
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        zIndex: "1000",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "0 16px 0 16px",
          flexGrow: 1,
        }}
      >
        <Box>
          <Typography
            sx={{
              fontFamily: "'Pretendard-SemiBold', Helvetica",
              fontWeight: "bold",
              color: "#f6f6f2",
              fontSize: "0.8rem",
            }}
          >
            시술이 장바구니에 추가되었습니다.
          </Typography>
        </Box>
        <Box>
          <Link to={`/${language}/cart`}>
            <Typography
              sx={{
                fontFamily: "'Pretendard-Regular', Helvetica",
                fontWeight: "normal",
                color: "white",
                fontSize: "0.8rem",
                marginRight: 1,
                textDecoration: "underline",
              }}
            >
              보러가기
            </Typography>
          </Link>
        </Box>
      </Box>
    </Box>
    // document.body
  );
};

export default CartAddedPopup;
