import React from "react";
import ReactDOM from "react-dom";
import { Box, Button, Typography } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import ChatIcon from "@mui/icons-material/Chat";
import EventNoteIcon from "@mui/icons-material/EventNote";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../LanguageContext";
import kakaotalkIcon from "./images/kakaotalkIcon.jpg";
import phoneIcon from "./images/phoneIcon.png";

interface CouncelingMethodPopup {
  onClose: () => void;
}

const CouncelingMethodPopup: React.FC<CouncelingMethodPopup> = ({
  onClose,
}) => {
  const navigate = useNavigate();
  const { language } = useLanguage(); // 현재 언어 상태 가져오기

  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "70%",
        maxWidth: 350,
        height: "35%",
        maxHeight: 200,
        bgcolor: "white",
        borderRadius: 2,
        overflow: "hidden",
        p: 1,
        zIndex: 100,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        alignItems: "center",
        gap: 1.5,
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <Box
        sx={{
          width: "33%",
          height: "fit-content",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: 1,
          marginBottom: 4,
        }}
      >
        <Button
          variant="contained"
          sx={{
            width: "50px",
            aspectRatio: "1",
            maxHeight: 100,
            bgcolor: "#d29094ee",
            borderRadius: "50%",
            position: "relative",
            color: "#3f3936",
            fontWeight: "800",
            textAlign: "center",
          }}
          onClick={() => {
            navigate(`${language}/cart/1`);
            onClose();
          }}
        >
          <EventNoteIcon
            sx={{
              width: "100%",
              height: "auto",
              borderRadius: "50%",
              color: "black",
            }}
          />
        </Button>
        <Typography
          sx={{
            fontFamily: "Pretendard",
            fontSize: "0.9rem",
            fontWeight: "500",
          }}
        >
          내원상담
        </Typography>
      </Box>
      <Box
        sx={{
          width: "33%",
          height: "fit-content",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: 1,
          marginBottom: 4,
        }}
      >
        <Button
          variant="contained"
          sx={{
            width: "50px",
            aspectRatio: "1",
            maxHeight: 100,
            bgcolor: "#57a959c7",
            borderRadius: "50%",
            position: "relative",
            color: "#3f3936",
            fontWeight: "800",
            textAlign: "center",
          }}
          onClick={() => (window.location.href = "tel:0262293499")}
        >
          <img
            src={phoneIcon}
            alt="phone"
            style={{
              width: "100%",
              height: "auto",
              borderRadius: "50%",
            }}
          />
        </Button>
        <Typography
          sx={{
            fontFamily: "Pretendard",
            fontSize: "0.9rem",
            fontWeight: "500",
          }}
        >
          전화문의
        </Typography>
      </Box>
      <Box
        sx={{
          width: "33%",
          height: "fit-content",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: 1,
          marginBottom: 4,
        }}
        onClick={() => window.open("http://pf.kakao.com/_xhxmizn/", "_blank")}
      >
        <img
          src={kakaotalkIcon}
          alt="kakaotalk"
          style={{
            width: "65px",
            aspectRatio: "1",
            cursor: "pointer",
            // height: "auto",
            // borderRadius: "50%",
          }}
        />
        {/* </Button> */}
        <Typography
          sx={{
            fontFamily: "Pretendard",
            fontSize: "0.9rem",
            fontWeight: "500",
          }}
        >
          카톡문의
        </Typography>
      </Box>
      <Button
        variant="contained"
        sx={{
          width: "90%",
          height: "15%",
          maxHeight: 50,
          bgcolor: "#f6f6f2",
          borderRadius: 1,
          position: "fixed",
          bottom: 20,
          color: "#a7a78c",
          fontWeight: "600",
          textAlign: "center",
        }}
        onClick={onClose}
      >
        닫기
      </Button>
    </Box>
  );
};

export default CouncelingMethodPopup;
