// Firestore에 데이터를 저장하는 Node.js 스크립트
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  setDoc,
  doc,
  collection,
  updateDoc,
  getDoc,
} from "firebase/firestore";
import React, { useEffect } from "react";
import category from "../components/text_assets/category.json";
import brief from "../components/text_assets/brief.json";
import event from "../components/text_assets/event.json";
import eventCategory from "../components/text_assets/eventCategory.json";
import title from "../components/text_assets/title.json";
import startingPrice from "../components/text_assets/startingPrice.json";
import detail from "../components/text_assets/detail.json";
import bodyCategory from "../components/text_assets/bodyCategory.json";

const XLSX = require("xlsx");

function Upload() {
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_CONFIG_APIKEY,
    authDomain: process.env.REACT_APP_FIREBASE_CONFIG_AUTODOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_CONFIG_PROJECTID,
    storageBucket: process.env.REACT_APP_FIREBASE_CONFIG_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_CONFIG_MESSAINGSENDERID,
    appId: process.env.REACT_APP_FIREBASE_CONFIG_APPID,
    measurementId: process.env.REACT_APP_FIREBASE_CONFIG_MEASUREMENTID,
  };
  // Firebase 초기화
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);

  async function updateContentsFromJson() {
    try {
      // jsonData의 각 id에 대해 Firestore 업데이트
      // const updatePromises = Object.keys(category14).map(async (id) => {
      // const intId = parseInt(parseInt(id) / 100);
      const docRef = doc(db, "category", "406"); // 문서 참조 가져오기
      // const contents = category[id]; // 해당 id의 행렬 데이터
      // const briefV = brief[id];
      // const events = event[id]; // 해당 id의 이벤트 데이터
      // const cat14 = [id];

      // Firestore에 contents 필드 업데이트
      await updateDoc(docRef, {
        // description: details.description,
        // recommendation: details.recommendation,
        // precaution: details.precaution,
        // feature: details.feature,
        // FAQ: details.FAQ,
        contents: bodyCategory,
      });

      console.log("406 cateogry updated successfully!");
      // });

      // 모든 업데이트가 완료될 때까지 기다림
      // await Promise.all(updatePromises);

      console.log("All documents updated successfully!");
    } catch (error) {
      console.error("Error updating contents field:", error);
    }
  }
  async function uploadEvent() {
    try {
      for (const [key, value] of Object.entries(eventCategory)) {
        // 각 key를 문서 ID로 사용하고 value를 문서 내용으로 설정
        const docRef = doc(db, "eventTheme", key);
        await setDoc(docRef, value);
        console.log(`Document with ID ${key} has been added to collection`);
      }
      console.log("All documents uploaded successfully!");
    } catch (error) {
      console.error("Error uploading documents: ", error);
    }
  }

  async function updateCategoriesFromBriefs(file) {
    for (const [id, value] of Object.entries(file)) {
      const index = parseInt(id); // key를 숫자로 변환

      // 1. Firestore 문서 ID 계산
      const docId = Math.floor(index / 100).toString(); // 100으로 나눈 몫

      try {
        // 2. Firestore에서 문서 가져오기
        const docRef = doc(db, "theme", docId); // 컬렉션 이름 변경
        // await docRef.update({ title: value });
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          // 3. categories 배열 가져오기
          const data = docSnap.data();
          const categories = data.categories || [];

          // 4. categories 배열 업데이트
          const updatedCategories = categories.map((item) => {
            if (String(item.category) === id) {
              return { ...item, startingPrice: value }; // title 추가
            }
            return item; // 일치하지 않는 경우 그대로
          });

          // 5. Firestore 업데이트
          await updateDoc(docRef, { categories: updatedCategories });
          console.log(
            `Document ${docId} updated successfully for index ${index}.`
          );
        } else {
          console.log(`Document ${docId} does not exist.`);
        }
      } catch (error) {
        console.error(
          `Error updating document ${docId} for index ${index}:`,
          error
        );
      }
    }
  }

  // async function updateDocuments() {
  //   try {
  //     const promises = Object.keys(updates).map((id) => {
  //       // db.collection("category").doc(id).update(updates[id]);
  //       collection(db, "category").doc(id).update(updates[id]);
  //     });

  //     await Promise.all(promises);
  //     console.log("Documents successfully updated");
  //   } catch (error) {
  //     console.error("Error updating documents:", error);
  //   }
  // }
  // async function uploadJsonData() {
  //   const themeData = caution;
  //   try {
  //     // 각각의 아이템을 Firestore에 저장
  //     for (const [key, value] of Object.entries(themeData)) {
  //       await setDoc(doc(db, "caution", key), value);
  //       console.log(`${key} data uploaded successfully.`);
  //     }
  //   } catch (e) {
  //     console.error("Error adding document: ", e);
  //   }
  // }
  useEffect(() => {
    // uploadEvent();
    updateContentsFromJson();
    // updateCategoriesFromBriefs(startingPrice);

    // uploadJsonData();
    // const data = excelToJson("uploadExcel.xlsx");
    // console.log(data);
  }, []);

  return (
    <div className="container">
      <h1 className="title">주의사항</h1>
      <ul style={{ padding: 0 }}>안녕하세요</ul>
    </div>
  );
}
export default Upload;
