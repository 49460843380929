export function getParsedLocalStorageItem<T>(key: string): T | null {
  const item = localStorage.getItem(key);
  if (item) {
    try {
      return JSON.parse(item) as T;
    } catch (error) {
      console.error(
        `Error parsing JSON from localStorage key "${key}":`,
        error
      );
      return null;
    }
  }
  return null;
}

/**
 * Stringifies a value and sets it in localStorage.
 * @param {string} key - The key of the item to set in localStorage.
 * @param {any} value - The value to stringify and set in localStorage.
 */
export function setLocalStorageItem(key: string, value: any) {
  try {
    const jsonString = JSON.stringify(value);
    localStorage.setItem(key, jsonString);
  } catch (error) {
    console.error(
      `Error stringifying value for localStorage key "${key}":`,
      error
    );
  }
}
