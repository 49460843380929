import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import SearchModule from "./function_components/SearchModule";
// MUI import
import {
  Box,
  Typography,
  IconButton,
  AppBar,
  Toolbar,
  Tabs,
  Tab,
  Menu,
  MenuItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import LanguageIcon from "@mui/icons-material/Language";
import SearchIcon from "@mui/icons-material/Search";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import logo_home1x_white_no_background from "./images/logo_home1x_white_no_background.png";
import { useLanguage } from "../LanguageContext";
import { set } from "date-fns";
import cartCircle from "./images/cartCircle.png";

interface HeaderProps {
  menuOpen: boolean;
  setMenuOpen: (arg0: boolean) => void;
  searchOpen: boolean;
  setSearchOpen: (arg0: boolean) => void;
  cartLength: number;
  updateCartLength: () => void;
  setLastParams: (arg0: string) => void;
  languageMenuOpen: boolean;
  setLanguageMenuOpen: (arg0: boolean) => void;
}

const Header2: React.FC<HeaderProps> = ({
  menuOpen,
  setMenuOpen,
  searchOpen,
  setSearchOpen,
  cartLength,
  updateCartLength,
  setLastParams,
  languageMenuOpen,
  setLanguageMenuOpen,
}) => {
  const [tabState, setTabState] = useState<number>(0);
  const { language } = useLanguage(); // 현재 언어 상태 가져오기

  const navigate = useNavigate();

  useEffect(() => {
    // Initial load
    updateCartLength();
  }, [cartLength]);

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        width: "100%",
        height: 50,
        overflow: "hidden",
        maxWidth: "750px" /* Match the max-width of the container */,
        zIndex: 100,
        pointerEvents: "auto",
        backdropFilter: "blur(6px) brightness(80%)",
      }}
    >
      <AppBar
        position="static"
        sx={{
          backgroundColor: "rgba(112, 107, 105, 0.48)",
          boxShadow: "0px 0px 0px 0px",
          height: 50,
          pointerEvents: "auto",
          width: "100%",
        }}
      >
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Box
            sx={{
              position: "absolute",
              left: window.innerWidth > 750 ? "17px" : "12px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              gap: window.innerWidth > 750 ? "25px" : "10px",
            }}
          >
            <IconButton onClick={() => setMenuOpen(true)}>
              <MenuIcon sx={{ color: "white" }} />
            </IconButton>
            <IconButton
              onClick={() => {
                setLanguageMenuOpen(!languageMenuOpen);
              }}
            >
              <LanguageIcon sx={{ color: "white" }} />
            </IconButton>
          </Box>
          <Box
            sx={{
              position: "absolute",
              left: "50%",
              width: "30%",
              maxWidth: "150px",
              height: "80%",
              transform: "translateX(-50%)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Link
              to={`/${language}`}
              style={{
                textDecoration: "none",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img
                src={logo_home1x_white_no_background}
                alt="PRIA"
                style={{
                  width: "80%",
                  height: "auto",
                  cursor: "pointer",
                  alignSelf: "center",
                }}
                onClick={() => {
                  setTabState(0);
                  setSearchOpen(false);
                  setMenuOpen(false);
                }}
              />
            </Link>
          </Box>
          <Box
            sx={{
              position: "absolute",
              top: "15px",
              right: "15px",
              display: "flex",
              alignItems: "center",
              transform: "translateZ(0)",
              "&:active": {
                transform: "none", // 터치 시 위치 변화 방지
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: window.innerWidth > 750 ? "40px" : "25px",
              }}
            >
              <SearchIcon
                sx={{ color: "white", cursor: "pointer" }}
                onClick={() => {
                  setMenuOpen(false);
                  navigate(`/${language}/search`);
                }}
              />
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "row",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setMenuOpen(false);
                  navigate(`/${language}/cart`);
                }}
              >
                <ShoppingCartIcon sx={{ color: "white", cursor: "pointer" }} />
                {cartLength > 0 ? (
                  <sub
                    style={{
                      marginLeft: -11,
                      marginTop: 10,
                    }}
                  >
                    <Box
                      sx={{
                        width: "20px",
                        height: "20px",
                        backgroundColor: "black",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        component="span"
                        sx={{
                          fontSize: "0.75rem",
                          color: "white",
                        }}
                      >
                        {cartLength}
                      </Typography>
                    </Box>
                  </sub>
                ) : (
                  <></>
                )}
              </Box>
            </Box>
            {/* <Link to={`/${language}/cart`}>
              <IconButton
                sx={{
                  position: "relative",
                  transform: "translateZ(0)",
                  WebkitTapHighlightColor: "transparent",
                  "&:active": {
                    transform: "none", // 터치 시 위치 변화 방지
                    position: "relative",
                  },
                }}
                onClick={() => setMenuOpen(false)}
              >
                <ShoppingCartIcon
                  sx={{ color: "white", pointerEvents: "none" }}
                />
                {cartLength > 0 ? (
                  <sub
                    style={{
                      marginLeft: -11,
                      marginTop: 10,
                    }}
                  >
                    <Box
                      sx={{
                        width: "20px",
                        height: "20px",
                        backgroundColor: "black",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        component="span"
                        sx={{
                          fontSize: "0.75rem",
                          color: "white",
                        }}
                      >
                        {cartLength}
                      </Typography>
                    </Box>
                  </sub>
                ) : (
                  <></>
                )}
              </IconButton>
            </Link> */}
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header2;
