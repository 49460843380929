import React, { useState, useEffect } from "react";
import "./all_css/Cart.css";
import "react-datepicker/dist/react-datepicker.css";
import Authentication from "./Authentication";
import {
  Box,
  Typography,
  IconButton,
  Grid2,
  Divider,
  Button,
  TextField,
  List,
  ListItem,
  Checkbox,
  Chip,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import FormControlLabel from "@mui/material/FormControlLabel";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { Link, useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import "dayjs/locale/ko"; // 한국어 로케일로 예시 (숫자 형식 사용 가능)
import { useLanguage } from "../LanguageContext";

function Cart({
  isVerified,
  setIsVerified,
  cartLength,
  updateCartLength,
  userName,
  setUserName,
  phoneNumber,
  setPhoneNumber,
}) {
  //장바구니 전체

  const [cartItems, setCartItems] = useState(() => {
    const storedCart = localStorage.getItem("cart");
    return storedCart && storedCart.trim() !== "" ? JSON.parse(storedCart) : [];
  });
  //장바구니 중 체크해서 최종선택한 항목
  const [checkedItems, setCheckedItems] = useState(
    new Array(cartItems.length).fill(true)
  );
  // 예약 날짜와 시간
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const { language } = useLanguage(); // 현재 언어 상태 가져오기

  //추가 요청 사항
  const [userMemo, setUserMemo] = useState(
    sessionStorage.getItem("userMemo") || ""
  );

  //빈시간슬롯
  const [emptySlots, setEmptySlots] = useState(Array(20).fill(0));

  //리렌더링 방지를 위한 자체 verified boolean
  // const [cartVerified, setCartVerified] = useState(isVerified);
  const [cartVerified, setCartVerified] = useState(() => {
    const storedValue = sessionStorage.getItem("verified");
    return storedValue !== null ? true : false; // 세션스토리지 값이 없으면 기본값 false
  });
  const [cartUserName, setCartUserName] = useState(() => {
    const storedValue = sessionStorage.getItem("userName");
    return storedValue !== null ? storedValue : ""; // 세션스토리지 값이 없으면 기본값 ""
  });
  const [cartPhoneNumber, setCartPhoneNumber] = useState(() => {
    const storedValue = sessionStorage.getItem("phoneNumber");
    return storedValue !== null ? storedValue : ""; // 세션스토리지 값이 없으면 기본값 ""
  });

  const navigate = useNavigate();
  const { urlCategory } = useParams();

  //상담 선택 여부
  const [counceling, setCounceling] = useState(
    urlCategory === "1" ? true : false
  );

  //약관 동의 여부
  const [agreed, setAgreed] = useState(false);

  const handleRemoveFromCart = (indexToRemove) => {
    const currentCart = JSON.parse(localStorage.getItem("cart")) || [];
    const updatedCart = currentCart.filter(
      (_, index) => index !== indexToRemove
    );
    localStorage.setItem("cart", JSON.stringify(updatedCart));
    setCartItems(updatedCart); // 상태 업데이트
    updateCartLength();
  };

  //달력 요일 설정
  const disableWeekends = (date) => {
    if (!date) return false; // 날짜가 없는 경우 처리
    const jsDate = date.toDate(); // dayjs 객체를 JavaScript Date로 변환
    const day = jsDate.getDay(); // 요일 계산 (0: 일요일, 6: 토요일)
    return day === 0; // 일요일(0) 또는 토요일(6) 비활성화
  };

  const handleCheckboxChange = (index) => {
    const updatedCheckedItems = checkedItems.map((item, i) =>
      i === index ? !item : item
    );
    setCheckedItems(updatedCheckedItems);
  };

  const entireCheckbox = (event) => {
    if (event.target.checked) {
      const updatedCheckedItems = checkedItems.map((item) => true);
      setCheckedItems(updatedCheckedItems);
    } else {
      const updatedCheckedItems = checkedItems.map((item) => false);
      setCheckedItems(updatedCheckedItems);
    }
  };
  const calculateTotalPrice = () => {
    return cartItems.reduce((total, item, index) => {
      return checkedItems[index] ? total + item.price : total;
    }, 0);
  };

  const handleDateChange = (newDate) => {
    if (newDate) {
      const formattedDate = newDate.format("YYYY-MM-DD"); // dayjs의 format 메서드
      setSelectedDate(newDate);
    }
  };

  const timeSlots = (date) => {
    if (!date) return []; // 날짜가 없을 경우
    const jsDate = date.toDate(); // dayjs 객체를 JavaScript Date로 변환
    const day = jsDate.getDay(); // 요일 계산 (0: 일요일, 6: 토요일)
    const timeSlots = [];
    if (day < 6) {
      for (let hour = 11; hour <= 20; hour++) {
        timeSlots.push(`${hour}:00`);
        timeSlots.push(`${hour}:30`);
      }
    } else {
      for (let hour = 10; hour <= 16; hour++) {
        timeSlots.push(`${hour}:00`);
        timeSlots.push(`${hour}:30`);
      }
    }
    return timeSlots;
  };
  const calcEndTime = (timeString) => {
    const [hours, minutes] = timeString.split(":").map(Number);
    if (minutes == "30") {
      const newhour = 1 + parseInt(hours, 10);
      const endtime = `${newhour}:00`;
      return endtime;
    } else {
      const endtime = `${hours}:30`;
      return endtime;
    }
  };

  async function findEmptyTime(date) {
    const formattedDate = date.format("YYYY-MM-DD"); // dayjs의 format 메서드
    const token = process.env.REACT_APP_DRCRM_TOKEN; // 실제 토큰으로 교체
    const url = `https://reservation.api.receipt.smartdoctor.systems/reservation-api/v2/reservationCounts?reservationDate=${formattedDate}`;
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      const zerotimes = Array(20).fill(0);
      data.reservationCounts?.forEach((item, index) => {
        const time = item.reservationTime;
        const day = new Date(date).getDay();
        const [si, boon, cho] = time.split(":").map(Number);
        const order =
          day < 6 ? 2 * (si - 11) + boon / 30 : 2 * (si - 10) + boon / 30;
        zerotimes[order] = item.count;
      });
      setEmptySlots(zerotimes);
    } catch (error) {
      console.error("Fetch error:", error);
    }
  }

  async function serverReservation() {
    if (cartItems.length === 0 && !counceling) {
      alert("장바구니가 비어있습니다.");
      return;
    } else if (selectedDate === null || selectedTime === null) {
      alert("날짜와 시간을 선택해주세요.");
      return;
    } else if (!cartVerified) {
      alert("본인인증을 진행해주세요.");
      return;
    } else if (!agreed) {
      alert("개인(진료)정보 제3자 제공에 동의해주세요.");
      return;
    } else {
      const checked = cartItems.filter((item, index) => checkedItems[index]);
      checked.push({
        title: "USERMEMO",
        price: 0,
        key: 0,
        event: 0,
        description: userMemo,
      });

      const url =
        "https://reservation.api.receipt.smartdoctor.systems/reservation-api/v2/customers";
      const token = process.env.REACT_APP_DRCRM_TOKEN; // 실제 토큰으로 교체
      const customerData = {
        citizenNumber: "2401011111111",
        cellPhone: cartPhoneNumber,
        name: cartUserName,
      };
      try {
        const response = await fetch(url, {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(customerData),
        });

        if (!response.ok) {
          throw new Error(
            "Network response while checking customer was not ok"
          );
        }
        const data = await response.json();

        const customerNumber = data.customer?.customerNumber;
        const url2 = `https://reservation.api.receipt.smartdoctor.systems/reservation-api/v2/customers/${customerNumber}/reservations`;
        const reservationData = {
          subjectCode: "14",
          departmentCode: "60",
          chargeDoctorId: "doctor",
          reservationDate: selectedDate.format("YYYY-MM-DD"),
          reservationStartTime: selectedTime,
          reservationEndTime: calcEndTime(selectedTime),
          labelId: 0,
          etcMemo: !counceling
            ? JSON.stringify(checked)
            : JSON.stringify([
                {
                  title: "USERMEMO",
                  price: 0,
                  key: 0,
                  event: 0,
                  description: userMemo,
                },
              ]),
          reservationMemo: "",
          sendMessage: true,
        };
        try {
          const response2 = await fetch(url2, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(reservationData),
          });
          setIsVerified(true);
          setUserName(cartUserName);
          setPhoneNumber(cartPhoneNumber);

          sessionStorage.setItem("userMemo", "");
          localStorage.removeItem("cart");
          alert("예약이 완료되었습니다!");
          // console.log("Reservation response:", await response2.json());
          // console.log("Selected Date: ", selectedDate.format("YYYY-MM-DD"));
          // console.log("Selected Time: ", selectedTime);
          navigate(`/${language}/history`);
          // userMemo 초기화
        } catch (error) {
          console.log("error while sending reservation to server:", error);
        }

        const smsURL =
          "https://us-central1-pria1-5e67a.cloudfunctions.net/sendSMS";
        try {
          const response = await fetch(smsURL, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              key: process.env.REACT_APP_ALIGO_APIKEY,
              user_id: process.env.REACT_APP_ALIGO_USERID,
              sender: "01084973499",
              receiver: "01047722195",
              msg: `[프리아의원] ${selectedDate.format(
                "YYYY-MM-DD"
              )} ${selectedTime}에 ${cartUserName}님 예약함.`,
            }),
          });
        } catch (error) {
          console.log("Error sending SMS:", error);
        }
      } catch (error) {
        console.error("Error sending reservation:", error);
      }
    }
  }

  useEffect(() => {
    const storedCart = localStorage.getItem("cart");
    setCartItems(
      storedCart && storedCart.trim() !== "" ? JSON.parse(storedCart) : []
    );
    // setCartItems(JSON.parse(localStorage.getItem("cart")) || []);
    if (!cartUserName) {
      setCartUserName(sessionStorage.getItem("userName"));
    }
    if (!cartPhoneNumber) {
      setCartPhoneNumber(sessionStorage.getItem("phoneNumber"));
    }
    // console.log("userName: ", cartUserName);
    // console.log("phoneNumber: ", cartPhoneNumber);

    setUserMemo(sessionStorage.getItem("userMemo") || "");
    setIsVerified(
      JSON.stringify(sessionStorage.getItem("verified")) === "true"
        ? true
        : false
    );
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    return () => {
      sessionStorage.setItem("userMemo", userMemo);
    };
  }, [userMemo]);
  useEffect(() => {
    setCheckedItems(new Array(cartItems.length).fill(true));
  }, [cartItems]);

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "white",
        overflowX: "hidden",
      }}
    >
      <Box sx={{ padding: "16px 0 16px 16px" }}>
        <Typography
          variant="h6"
          sx={{
            fontFamily: "Pretendard",
            fontWeight: "500",
            color: "black",
          }}
        >
          예약하기
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", paddingBottom: 2 }}>
        <ToggleButtonGroup
          value={counceling}
          exclusive
          aria-label="text alignment"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#f6f6f1",
            width: "70%",
            height: "40px",
            borderRadius: "10px",
          }}
        >
          <ToggleButton
            value={false}
            aria-label="left aligned"
            sx={{
              width: "50%",
              fontFamily: "Pretendard",
              padding: "5px 10px 5px 10px",
            }}
            onClick={() => {
              setCounceling(false);
              navigate(`/${language}/cart`);
            }}
          >
            시술 직접 선택
          </ToggleButton>
          <ToggleButton
            value={true}
            aria-label="right aligned"
            sx={{
              width: "50%",
              fontFamily: "Pretendard",
              padding: "5px 10px 5px 10px",
            }}
            onClick={() => {
              setCounceling(true);
              navigate(`/${language}/cart/1`);
            }}
          >
            상담 후 결정
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      {counceling ? (
        <Box
          sx={{
            position: "relative",
            width: "100%",
            backgroundColor: "white",
            padding: "24px 0 0 0",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#f6f6f2",
              width: "60%",
              p: 1.5,
              borderRadius: 3,
            }}
          >
            <Typography
              align="center"
              sx={{ fontFamily: "Pretendard", size: "1.125rem" }}
            >
              1대1 맞춤상담 후에
              <br /> 시술을 결정하실 수 있습니다.
            </Typography>
          </Box>
        </Box>
      ) : cartItems.length > 0 ? (
        <>
          <Box
            sx={{
              padding: 2,
              paddingTop: 1,
              backgroundColor: "#f6f6f1",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              flexWrap: "wrap",
              gap: "16px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: 0,
              }}
            >
              <FormControlLabel
                label="전체선택"
                control={
                  <Checkbox
                    defaultChecked={true}
                    color="default"
                    onChange={entireCheckbox}
                  />
                }
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  padding: 0,
                  mb: -2,
                  "& .MuiFormControlLabel-label": {
                    fontFamily: "Pretendard",
                    fontSize: "0.9rem",
                    fontWeight: "500",
                    color: "#3f3936",
                  },
                }}
              />
              {/* <Box>
                <p
                  style={{
                    fontFamily: "Pretendard",
                    fontSize: "0.9rem",
                    fontWeight: "500",
                    color: "#3f3936",
                  }}
                >
                  선택삭제
                </p>
              </Box> */}
            </Box>
            <Divider
              variant="middle"
              sx={{
                position: "relative",
                width: "90%",
              }}
            />

            {cartItems.map((item, index) => (
              <Box
                key={index}
                sx={{
                  position: "relative",
                  width: "100%",
                  backgroundColor: "#f6f6f2",
                  paddingBottom: "3vh",
                }}
              >
                <IconButton
                  key={index}
                  sx={{
                    position: "absolute",
                    top: 0,
                    padding: 0,
                    right: "5%",
                    cursor: "pointer",
                    zIndex: 100,
                  }}
                  onClick={() => handleRemoveFromCart(index)}
                >
                  <CloseIcon />
                </IconButton>
                <Box
                  sx={{
                    display: "flex",
                    position: "relative",
                    top: "10%",
                  }}
                >
                  <Checkbox
                    defaultChecked={true}
                    checked={checkedItems[index]}
                    color="default"
                    onChange={() => handleCheckboxChange(index)}
                    sx={{
                      paddingRight: "8px",
                      paddingLeft: 0,
                      paddingTop: item.event > 0 ? "24px" : 0,
                      display: "flex",
                      alignItems: "flex-start",
                    }}
                  />
                  <Box>
                    <Box
                      display="flex"
                      gap={0.5}
                      sx={{ mb: 0.5 }}
                      key={index + 50}
                    >
                      <Chip
                        label={
                          item.event === 1
                            ? "EVENT"
                            : item.event === 2
                            ? "오픈"
                            : "정가"
                        }
                        key={index}
                        sx={{
                          height: "20px",
                          backgroundColor:
                            item.event === 1
                              ? "#8c6e52"
                              : item.event === 2
                              ? "#f78b4f"
                              : "grey",
                          color: "white",
                          fontWeight: "semi-bold",
                          fontSize: 11,
                          borderRadius: 0,
                          fontFamily: "Pretendard",
                          "& .MuiChip-label": {
                            paddingLeft: "4px", // 좌측 패딩 설정
                            paddingRight: "4px", // 우측 패딩 설정
                          },
                        }}
                      />
                    </Box>
                    <Typography
                      sx={{
                        fontFamily: "Pretendard",
                        fontSize: "1.125rem",
                        "@media (max-width: 750px)": {
                          fontSize: "1rem", // 모바일 모드 글자 크기
                        },
                        fontWeight: "semi-bold",
                        color: "#3f3936",
                      }}
                    >
                      {item.title}
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Pretendard",
                        fontSize: "0.85rem",
                        color: "#696f6aee",
                      }}
                    >
                      {item.description ? (
                        item.description.split("@").map((line, index) => (
                          <span key={index}>
                            {line}
                            <br />
                          </span>
                        ))
                      ) : (
                        <br />
                      )}
                    </Typography>
                    <Typography
                      component="div"
                      sx={{
                        marginTop: "8px",
                        fontFamily: "Pretendard",
                        fontSize: "1.125rem",
                        "@media (max-width: 750px)": {
                          fontSize: "0.9rem", // 모바일 모드 글자 크기
                        },
                        fontWeight: "500",
                        color: "#3f3936",
                      }}
                    >
                      {item.price.toLocaleString("en-US")}원
                    </Typography>
                  </Box>
                </Box>
                <Divider
                  variant="middle"
                  sx={{
                    position: "absolute",
                    width: "90%",
                    bottom: "0%",
                  }}
                />
              </Box>
            ))}
            <Link to={`/${language}/reservation`}>
              <Button
                variant="outlined"
                fullWidth
                sx={{
                  mt: 2,
                  py: 1,
                  borderColor: "#827a72",
                  color: "#3f3936",
                  bgcolor: "#e3e0d7",
                  fontFamily: "Pretendard",
                  fontWeight: "600",
                  fontSize: "1rem",
                }}
                startIcon={<AddIcon />}
              >
                시술 추가하기
              </Button>
            </Link>
          </Box>
          <Box
            sx={{
              padding: 3,
              paddingTop: 1,
              backgroundColor: "#f6f6f1",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Pretendard",
                color: "#1e1e1e",
                fontSize: "0.9rem",
              }}
            >
              결제는 내원시 진행됩니다.
            </Typography>
            <Typography
              sx={{
                fontFamily: "Pretendard",
                color: "#1e1e1e",
                fontSize: "0.8rem",
                color: "#838383",
                marginBottom: 2,
              }}
            >
              *모든 가격은 부가세 별도입니다.
            </Typography>
            <Box
              sx={{ display: "flex", justifyContent: "flex-end", gap: "16px" }}
            >
              <Typography
                variant="body1"
                sx={{ color: "#838383", fontWeight: "bold" }}
              >
                총 예상 금액
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: "#3f3936",
                  fontFamily: "Pretendard",
                  fontSize: "1rem",
                  fontWeight: "600",
                }}
              >
                {calculateTotalPrice().toLocaleString("en-US")}원
              </Typography>
            </Box>
          </Box>
        </>
      ) : (
        <Box
          sx={{
            position: "relative",
            width: "100%",
            backgroundColor: "white",
            padding: "24px 0 24px 0",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#f6f6f2",
              width: "60%",
              p: 1.5,
              borderRadius: 3,
            }}
          >
            <Typography
              align="center"
              sx={{ fontFamily: "Pretendard", size: "1.125rem" }}
            >
              관심있는 시술 혹은 이벤트를
              <br /> 추가해주세요.
            </Typography>
          </Box>
          <Link to={`/${language}/reservation`}>
            <Button
              variant="outlined"
              sx={{
                width: "100%",
                mt: 1,
                py: 1,
                borderColor: "#827a72",
                color: "#3f3936",
                bgcolor: "white",
                fontFamily: "Pretendard",
              }}
            >
              시술/이벤트 추가하기
            </Button>
          </Link>
        </Box>
      )}
      <Box
        sx={{
          paddingTop: 4,
          paddingBottom: 4,
          paddingX: 2,
          display: "flex",
          flexDirection: "column",
          gap: 2.5,
          backgroundColor: "white",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Pretendard",
            fontWeight: "600",
            color: "#3f3936",
          }}
        >
          {counceling ? "1대1 맞춤상담" : "추가 요청 사항"}
        </Typography>
        <TextField
          variant="outlined"
          placeholder="상담받고 싶은 부위나 궁금하신 내용을 자유롭게 적어주세요."
          value={userMemo}
          onChange={(e) => {
            setUserMemo(e.target.value);
          }}
          fullWidth
          multiline
          rows={5}
          sx={{
            "& .MuiOutlinedInput-root": {
              padding: "18px 19px",
              borderColor: "#b3b3b3",
              borderRadius: 1,
            },
            "& .MuiInputBase-input": {
              fontFamily: "Pretendard-Medium, Helvetica",
              fontWeight: "medium",
              color: "grey",
            },
          }}
        />
      </Box>

      <div className="reservation-section">
        <div style={{ position: "relative", left: "5%" }}>
          <Typography
            sx={{
              fontFamily: "Pretendard",
              fontWeight: "600",
              color: "#3f3936",
            }}
          >
            날짜 선택
          </Typography>
        </div>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
          <DateCalendar
            value={selectedDate}
            onChange={(newDate) => {
              handleDateChange(newDate);
              findEmptyTime(newDate);
              setSelectedTime(null);
            }}
            shouldDisableDate={disableWeekends}
            minDate={dayjs("2024-12-23")}
            sx={{
              overflow: "hidden", // overflow 설정
              width: "90%", // DateCalendar의 전체 폭 조절
              "& .MuiDayCalendar-weekContainer": {
                display: "flex",
                justifyContent: "space-between", // 날짜가 컨테이너를 채우도록
              },
              "& .MuiPickersDay-root": {
                minWidth: "40px", // 날짜 셀의 최소 너비 조절
                height: "40px", // 날짜 셀의 높이 조절
                margin: "0 2px", // 셀 간의 간격 조절
                fontSize: "1rem", // 날짜 텍스트 사이즈 키우기
              },
              "& .MuiDayCalendar-header": {
                justifyContent: "space-between", // 주 헤더를 좌우로 펼치도록 설정
              },
              "& .MuiPickersCalendarHeader-root": {
                position: "relative",
              },
              "& .MuiPickersArrowSwitcher-root": {
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              },
              "& .MuiPickersCalendarHeader-labelContainer": {
                position: "absolute",
                left: "55%",
                transform: "translateX(-40%)",
                textAlign: "center",
                fontWeight: "bold",
                width: "30%",
              },
            }}
          />
        </LocalizationProvider>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            gap: 1,
          }}
        >
          <List
            sx={{
              left: "50%",
              transform: "translate(-50%)",
              display: "grid",
              gridTemplateColumns: "repeat(4, 1fr)", // 4열로 배치
              gap: 1, // 아이템 간격 조절
              padding: 0,
              width: "90%",
            }}
          >
            {timeSlots(selectedDate).map((time, index) => (
              <ListItem
                key={index}
                sx={{
                  width: "100%",
                  height: 38,
                  bgcolor:
                    emptySlots[index] > 3
                      ? "#e3e0d7"
                      : selectedTime === time
                      ? "#7d7a73"
                      : "white",
                  border: "0.5px solid #b3b3b3",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  borderRadius: 3,
                }}
                onClick={() => {
                  if (emptySlots[index] < 4) {
                    setSelectedTime(time);
                  }
                }}
              >
                <Typography
                  sx={{
                    fontFamily:
                      selectedTime === time
                        ? "Pretendard-SemiBold, Helvetica"
                        : "Pretendard-Medium, Helvetica",
                    fontWeight: selectedTime === time ? "bold" : "medium",
                    color:
                      selectedTime === time
                        ? "white"
                        : emptySlots[index] > 4
                        ? "grey"
                        : "#45474e",
                    fontSize: "0.875rem",
                    textAlign: "center",
                  }}
                >
                  {time}
                </Typography>
              </ListItem>
            ))}
          </List>
        </Box>
      </div>
      <Box sx={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
        <Authentication
          isVerified={cartVerified}
          setIsVerified={setCartVerified}
          userName={cartUserName}
          setUserName={setCartUserName}
          phoneNumber={cartPhoneNumber}
          setPhoneNumber={setCartPhoneNumber}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
          height: "50px",
          alignItems: "center",
          position: "relative",
          left: "4%",
          flexDirection: "row",
        }}
      >
        <FormControlLabel
          label="[필수]"
          control={
            <Checkbox
              defaultChecked={false}
              color="default"
              sx={{ padding: 0 }}
              onChange={() => setAgreed((prev) => !prev)}
            />
          }
          sx={{
            display: "flex",
            flexDirection: "row",
            margin: 0,
            "& .MuiFormControlLabel-label": {
              fontFamily: "Pretendard",
              fontSize: "0.8rem",
              fontWeight: "600",
              color: "#7d7a73",
            },
          }}
        />
        <Typography
          sx={{
            fontFamily: "Pretendard",
            color: "#3f3936",
            fontSize: "0.8rem",
            fontWeight: "500",
            marginLeft: "4px",
          }}
        >
          개인(진료)정보 제3자 제공에 동의합니다.
        </Typography>
        <Typography
          sx={{
            fontFamily: "Pretendard",
            color: "#3f3936",
            fontSize: "0.8rem",
            fontWeight: "600",
            cursor: "pointer",
            textDecoration: "underline",
          }}
          onClick={() => {
            navigate(`/${language}/policy`);
          }}
        >
          (보기)
        </Typography>
      </Box>
      <Box
        sx={{
          mt: 2,
          width: "100%",
          height: "200px",
        }}
      >
        <Button
          variant="contained"
          onClick={() => {
            serverReservation();
          }}
          sx={{
            left: "50%",
            transform: "translateX(-50%)",
            width: "85%",
            height: 46,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 1,
            backgroundColor: "#3f3936",
            borderRadius: 1,
            border: "1px solid transparent",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Pretendard-SemiBold, Helvetica",
              fontWeight: "bold",
              color: "#f6f6f2",
              fontSize: "0.875rem",
              textAlign: "center",
              whiteSpace: "nowrap",
            }}
          >
            예약하기
          </Typography>
        </Button>
      </Box>
    </div>
  );
}

export default Cart;
